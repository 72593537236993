@import url("../../styles/_variables.css");

:root {
  --product-name-lines: 2;
  --product-name-line-height: var(--small-line-height);
}

.name {
  display: -webkit-box;
  line-height: var(--product-name-line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: var(--spacing-level-3);
  -webkit-line-clamp: var(--product-name-lines);
  -webkit-box-orient: vertical; /* Required to bring ellipsis into the right position */
}

.createReturn {
  margin: 0;
}

.multiLine {
  height: calc(var(--product-name-line-height) * var(--product-name-lines));
}
