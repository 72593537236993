/* Generated by ParaType (http://www.paratype.com)
   Font Futura PT Book: Copyright © ParaType, 2015. All rights reserved.
   Font Futura PT Heavy: Copyright © ParaType, 2015. All rights reserved.
   Font Futura PT Extra Bold: Copyright © ParaType, 2015. All rights reserved. */

@font-face {
  font-family: futura-pt;
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.eot");
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.eot?#iefix") format("embedded-opentype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.woff2") format("woff2"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.woff") format("woff"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.ttf") format("truetype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN45__W.svg#FuturaPTWeb-Book") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: futura-pt;
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN65__W.eot");
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN45__W/FTN65__W.eot?#iefix") format("embedded-opentype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN65__W/FTN65__W.woff2") format("woff2"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN65__W/FTN65__W.woff") format("woff"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN65__W/FTN65__W.ttf") format("truetype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN65__W/FTN65__W.svg#FuturaPTWeb-Book") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: futura-pt;
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.eot");
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.eot?#iefix") format("embedded-opentype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.woff2") format("woff2"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.woff") format("woff"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.ttf") format("truetype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN75__W/FTN75__W.svg#FuturaPTWeb-Heavy") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: futura-pt;
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.eot");
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.eot?#iefix") format("embedded-opentype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.woff2") format("woff2"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.woff") format("woff"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.ttf") format("truetype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN46__W/FTN46__W.svg#FuturaPTWeb-BookObl") format("svg");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: futura-pt;
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.eot");
  src: url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.eot?#iefix") format("embedded-opentype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.woff2") format("woff2"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.woff") format("woff"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.ttf") format("truetype"), url("//assets.asosservices.com/MasterLayout/WebFonts/FTN96__W/FTN96__W.svg#FuturaPTWeb-ExtraBoldObl") format("svg");
  font-weight: 900;
  font-style: italic;
}
