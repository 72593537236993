@import url("../../styles/_variables.css");

.title {
  color: var(--color-black);
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  margin-bottom: var(--spacing-level-4);
  margin-top: var(--spacing-level-1);
}

.cardWrapper {
  margin-top: var(--spacing-level-3);
}

.outerWrapper {
  padding: var(--spacing-level-6) var(--spacing-level-4);
}

.innerWrapper {
  position: relative;
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.message {
  margin-bottom: var(--spacing-level-6);
}

@media (--s-breakpoint) {
  .outerWrapper {
    padding: 0 var(--spacing-level-8);
  }

  .title {
    margin-top: var(--spacing-level-3);
  }
}

@media (--m-breakpoint) {
  .hideOnLarge {
    display: none;
  }
}

.hideOnWebView {
  display: none;
}
