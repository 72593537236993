@import url("../../styles/_variables.css");

:root {
  --select-height: 44px;
}

.select {
  position: relative;
  width: 100%;
  max-width: var(--field-max-width);
  display: inline-block;
}

.select select {
  appearance: none;
  background: var(--color-white);
  border: 1px solid var(--asos-grey-70);
  border-radius: 0;
  color: var(--color-black);
  font-size: var(--body-size);
  height: var(--select-height);
  padding: 0 var(--spacing-level-7) 0 var(--spacing-level-4);
  width: 100%;

  &::-ms-expand {
    display: none;
  }
}

.select .chevronDown {
  pointer-events: none;
  width: var(--select-height);
  height: var(--select-height);
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("./icon-down-arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
}

.shortWidth {
  width: 68px;
}

.select select option {
  color: var(--color-black);
}
