@import url("../../../../styles/_variables.css");

.wrapper {
  background-color: var(--asos-world-violet);
  height: 132px;
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  align-items: center;
  margin-top: var(--spacing-level-6);
  background-size: 200px 185px;

  @media (--under-m-breakpoint) {
    flex-direction: column;
    align-items: flex-start;
    height: fit-content;
    padding: var(--spacing-level-3) 0;
  }

  h3 {
    background-color: var(--color-white);
    color: var(--color-full-black);
    text-transform: uppercase;
    width: fit-content;
    font-weight: 500;
    padding-left: var(--spacing-level-3);
    padding-right: var(--spacing-level-1);
    font-size: var(--h1-size);
    line-height: var(--h1-line-height);
    margin: var(--spacing-level-1) 0;
  }

  img, p {
    padding-left: var(--spacing-level-3);
    color: var(--color-white);
  }

  p {
    @media (--under-m-breakpoint) {
      width: 50vw;
    }
  }

  .qrCode {
    height: 100px;
    width: auto;
    padding-left: var(--spacing-level-6);

    @media screen and (--under-m-breakpoint) {
      display: none;
    }
  }

  .storeBadges {
    padding-top: var(--spacing-level-3);

    img {
      height: 40px;
    }

    @media (--m-breakpoint) {
      display: none;
    }
  }
}

.tier1 {
  background-image: url("../../tier-card/images/charms-tier-1.png");
}

.tier2 {
  background-image: url("../../tier-card/images/charms-tier-2.png");
}

.tier3 {
  background-image: url("../../tier-card/images/charms-tier-3.png");
}

.tier4 {
  background-image: url("../../tier-card/images/charms-tier-4.png");
}