.entry {
  opacity: 0;
  transform: translateY(10%);

  &.active {
    opacity: 1;
    transform: translateY(0%);
    transition: all 500ms;
  }
}
