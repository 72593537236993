@import url("../../styles/_variables.css");

.banner {
  background-color: var(--color-off-white);
  color: var(--color-dark-gray);
  display: block;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.2rem;
  line-height: 32px;
  height: 32px;
  margin-top: -32px;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
}

@media (--s-breakpoint) {
  .banner:active,
  .banner:focus {
    margin-top: 0;
  }
}
