@import url("../../styles/_variables.css");

.header,
.columnContent {
  height: 4.4rem;
}

.header {
  overflow: hidden;
  padding: 0;
  margin-bottom: var(--spacing-level-1);
}

.isLoyaltyWebview {
  display: none;
}

.columnContent {
  display: flex;
  align-items: center;
}

.columnContentInner {
  display: flex;
  align-items: center;
}

.columnContentInnerHeader {
  right: 0;
  left: 0;
  margin: auto;
}

.title {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
}

.asosLogo {
  display: inline-block;
  text-align: left;
  width: 5.156rem;
}

.vendorArea {
  justify-content: flex-end;
}

.titleLink {
  text-align: center;
}

.titleLink:hover {
  text-decoration: none;
  color: var(--color-dark-gray);
}

@media (--s-breakpoint) {
  .header,
  .columnContent {
    height: 5.4rem;
  }

  .asosLogo {
    width: 6.875rem;
  }
}

@media (--m-breakpoint) {
  .header,
  .columnContent {
    height: 7.2rem;
  }

  .title {
    font-size: 2.2rem;
    line-height: 1;
  }

  .asosLogo {
    width: 8.25rem;
  }
}

@media (--l-breakpoint) {
  .header,
  .columnContent {
    height: 8.8rem;
  }

  .title {
    font-size: 2.4rem;
  }

  .asosLogo {
    width: 9.625rem;
  }
}
