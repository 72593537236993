@import url("../../styles/_variables.css");

.cardWrapper {
  border: 1px solid var(--color-silver);
  border-radius: 10px;
}

.card {
  overflow: hidden;
  position: relative;
  padding-top: 58%;
}

.cardDisabled {
  opacity: 0.2;
}

.cardBackground {
  top: 25%;
  left: 0;
  position: absolute;
  width: 100%;
}

.asosLogo {
  position: absolute;
  top: 6px;
  right: 8px;
  width: 44px;
}

.cardDetails {
  padding: var(--spacing-level-2);
  position: absolute;
  bottom: 0;
  text-align: right;
  width: 100%;
}

.spaceUpBottom {
  bottom: 10px;
}

@media (--m-breakpoint) {
  .spaceUpBottom {
    bottom: 5px;
  }
}

@media (--l-breakpoint) {
  .asosLogo {
    top: 12px;
    width: 53px;
  }

  .spaceUpBottom {
    bottom: 10px;
  }
}

.giftCardBow {
  padding-left: 30%;
}

.flagIcon {
  display: inline-block;
  margin-bottom: 1px;
}

.currentValue,
.value {
  color: var(--color-dark-gray);
  text-transform: uppercase;
}

.value {
  padding-top: var(--spacing-level-1);
}

.valueWithFlag {
  display: inline-block;
  font-size: var(--h2-size);
  letter-spacing: var(--h2-spacing);
  line-height: var(--h2-line-height);
  margin-left: 5px;
  vertical-align: bottom;
}
