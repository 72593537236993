@import url("../../../styles/_variables.css");

.loadingLayout {
  position: absolute;
  inset: 0;
  z-index: 11;
  background: var(--color-gallery-gray);
}

.leave {
  opacity: 1;
}

.leave.leaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.header,
.columnContent {
  height: 4.4rem;
}

.header {
  overflow: hidden;
  padding: 0;
  margin-bottom: var(--spacing-level-1);
}

.columnContent {
  display: flex;
  align-items: center;
}

.asosLogo {
  width: 5.156rem;
}

.titleColumn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  width: 122px;
  height: 18px;
  line-height: 18px;
}

@media (--s-breakpoint) {
  .header,
  .columnContent {
    height: 5.4rem;
  }

  .asosLogo {
    width: 6.875rem;
  }
}

@media (--m-breakpoint) {
  .header,
  .columnContent {
    height: 7.2rem;
  }

  .asosLogo {
    width: 8.25rem;
  }

  .titleColumn {
    height: 8.8rem;
  }

  .title {
    width: 175px;
    height: 24px;
    line-height: 24px;
  }
}

@media (--l-breakpoint) {
  .header,
  .columnContent {
    height: 8.8rem;
  }

  .title {
    font-size: 2.4rem;
  }

  .asosLogo {
    width: 9.625rem;
  }
}
