@import url("../../../../../styles/_variables.css");

.content {
  line-height: var(--body-line-height);
  margin: var(--spacing-level-6) 0;
  text-align: center;
}

.helpLink {
  display: inline-block;
  margin-top: var(--spacing-level-6);
  text-decoration: underline;
}
