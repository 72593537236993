.flagWrapper {
  display: inline-block;
  position: relative;
}

.icon {
  display: block;
}

.default {
  height: 40px;
  width: 40px;
}

.small {
  height: 17px;
  width: 17px;
}

.iconShadow {
  position: absolute;
  inset: 0;
  border-radius: 50%;
}
