.stickySection {
  position: relative;
}

.stickyItemWrapper {
  position: relative;
}

.dark .stickyItemWrapper button {
    background-color: var(--asos-full-black);
    color: var(--asos-white);
}
