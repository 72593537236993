@import url("../../../../../styles/_variables.css");

.name,
.price {
  padding-bottom: var(--spacing-level-1);
}

@media (--m-breakpoint) {
  .price {
    padding-bottom: var(--spacing-level-2);
  }
}
