@import url("../../styles/_variables.css");

:root {
  --border-style: 1px solid var(--asos-grey-70);
  --icon-width: 32px;
  --input-horizontal-padding: var(--spacing-level-4);
}

.input {
  appearance: none;
  border-radius: 0;
  border: var(--border-style);
  flex: 1 1 auto;
  font-size: var(--body-size);
  line-height: 2.2rem; /* Should probably be --body-line-height */
  padding: 10px var(--input-horizontal-padding);
}

.input::placeholder {
  color: var(--asos-grey-70);
}

.inputWrapper {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;
}

.hasIcon {
  padding-right: calc(var(--input-horizontal-padding) * 2 + var(--icon-width));
}

.hasButton,
.hasButton:focus {
  border-right: none;
}

.hasButton:focus {
  padding-right: 70px;
}

.icon {
  height: auto;
  position: absolute;
  right: var(--input-horizontal-padding);
  top: 50%;
  transform: translateY(-50%);
  width: var(--icon-width);
}

.input[type="search"].search {
  background-image: url("./search.svg");
  background-position: var(--spacing-level-4);
  background-repeat: no-repeat;
  background-size: 13px;
  padding-left: var(--spacing-level-10);
}

.buttonWrapper {
  align-items: center;
  border: var(--border-style);
  border-left-style: none;
  display: flex;
  padding-right: var(--input-horizontal-padding);
}

.input:focus + .buttonWrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 44px;
}

.input[aria-invalid="true"] + .buttonWrapper {
  border-color: var(--color-lipstick-red);
}

.input[type="password"].password {
  font-family: Verdana, sans-serif;
  letter-spacing: 2px;
}
