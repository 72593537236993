@import url("../../../../../styles/_variables.css");

.bgImage {
  background: url("./premier_gradient-bg@2x.png") no-repeat center;
}

.detached {
  margin-bottom: var(--spacing-level-3);
}

.imageHeader {
  height: 68px;
  width: 341px;
}

.mobileBackLink {
  display: inline-block;
  justify-self: flex-start;
  width: var(--spacing-level-10);
}

.mobileHeading {
  display: inline-block;
  padding-right: var(--spacing-level-10);
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.showOnSmall {
  display: block;
}

.withHeadingImg {
  background-color: var(--color-white);
  padding: var(--spacing-level-8);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (--under-m-breakpoint) {
  .bgImage {
    background: none;
  }

  .withHeadingImg {
    background-color: var(--color-white);
    justify-content: flex-start;
    padding: 13px var(--spacing-level-4);
  }

  .imageHeader {
    display: none;
  }
}

@media (--m-breakpoint) {
  .showOnSmall {
    display: none;
  }
}
