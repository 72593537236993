@import url("../../styles/_variables.css");

.discountMessage {
  color: var(--color-dark-gray);
  font-size: var(--body-size);
  letter-spacing: var(--body-spacing);
  line-height: var(--body-line-height);
  padding-top: var(--spacing-level-6);
}

.feeMessage {
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: var(--spacing-level-2);
}

.itemsToReturn {
  font-weight: 600;
  text-transform: uppercase;
}

.postPurchaseChargeInformationMessage {
  padding-bottom: var(--spacing-level-4);
}

/* sorry very hacky */
.postPurchaseChargeInformationMessage strong,
.postPurchaseChargeInformationMessage b {
  font-weight: bold !important;
}

.refundCalculationDescription {
  padding-bottom: var(--spacing-level-6);
}

.paymentMethod {
  width: 95%;
}

.paymentMethod span {
  background-image: url("../../../src/components/picture-icon/img/open-new-window.svg");
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-right: 2.5rem;
}

.paymentMethod a,
.postPurchaseChargeInformationMessage a {
  text-decoration: underline;
}

.paymentMethod a:hover,
.postPurchaseChargeInformationMessage a:hover {
  text-decoration: none;
}

.subTotal {
  margin-right: var(--spacing-level-2);
}
