@import url("../../styles/_variables.css");

.digiCert img {
  max-width: 100% !important;
  width: 54px !important;
}

@media (--s-breakpoint) {
  .digiCert img {
    width: 64px !important;
  }
}

@media (--m-breakpoint) {
  .digiCert img {
    width: 76px !important;
  }
}

@media (--l-breakpoint) {
  .digiCert img {
    width: 80px !important;
  }
}
