@import url("../../../styles/_variables.css");

.wrapper {
  display: flex;
  align-items: center;
  background-color: transparent !important;

  img {
    height: 14px;
    margin: 0;
    padding-right: 0;
    border-radius: 5px;
  }

  span {
    padding-left: var(--spacing-level-1);
    text-transform: uppercase;
    font-size: var(--small-interactive-size);
    line-height: var(--body-line-height);
    font-weight: 500;
    letter-spacing: -0.7px;
  }
}

.medium {
  img {
    height: 18px;
  }

  span {
    font-size: var(--h2-size);
    line-height: var(--h1-size);
    font-weight: 500;
  }
}

/* Dark theme */
.wrapper.dark {
  span {
    color: var(--asos-white);
  }
}

.wrapper.dark.contrastBorder {
  img {
    border: thin solid var(--asos-grey-00);
  }
}