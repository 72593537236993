@import url("../../../../../styles/_variables.css");

:root {
  --optical-alignment-nudge: 2px;
  --icon-size: 20px;
  --cta-max-width: 180px;
}

.detail {
  position: relative;
}

.label {
  margin-bottom: var(--spacing-level-4);
}

.name {
  font-weight: bold;
  font-size: var(--h4-size);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-spacing);
  margin-bottom: var(--spacing-level-3);
  min-height: var(--icon-size);
  display: flex;
  align-items: center;
  position: relative;
}

.icon {
  height: var(--icon-size);
  margin-top: var(--optical-alignment-nudge);
  margin-right: var(--spacing-level-3);
}

.address {
  font-size: var(--body-size);
  line-height: var(--body-line-height);
  letter-spacing: var(--body-spacing);
}

.button {
  display: block;
  margin-top: var(--spacing-level-4);
}

.carrierInfo {
  padding-top: var(--spacing-level-4);
}

.infoButton {
  background: transparent;
  margin-left:  var(--spacing-level-4);
  border: none;
  cursor: pointer;
  margin-top: var(--spacing-level-2);
}

.innerButton {
  max-height: 32px;
  min-width: 100%;
  font-size: 14px;
}

@media (--s-breakpoint) and (--orders-list-l-breakpoint) {
  .button {
    position: absolute;
    right: 0;
    top: var(--spacing-level-2);
    margin-top: 0;
    max-width: 35%;
  }

  .text {
    max-width: 65%;
    padding-right: var(--spacing-level-3);
  }
}


@media (--under-orders-list-l-breakpoint) {
  .infoButton {
    position: absolute;
    right: 0;
    padding-right: 0;
  }
}
