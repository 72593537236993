@import url("../../../../styles/_variables.css");

.center {
  text-align: center;
}

.modalContent {
  line-height: var(--body-line-height);
  margin: var(--spacing-level-6) 0;
  text-align: center;
}

.modalHelpLink {
  display: inline-block;
  margin-top: var(--spacing-level-6);
}
