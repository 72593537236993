@import url("../../styles/_variables.css");

.overlay {
  align-items: center;
  background-color: transparent;
  inset: 0;
  display: flex;
  justify-content: center;
  padding: 0 var(--spacing-level-4);
  position: fixed;
  transition: background-color 0.15s ease-out;
  z-index: 15;
}

html:has(:global(.ReactModal__Body--open)) {
  overflow-y: hidden;
}

:global(.ReactModal__Overlay--after-open) {
  background-color: rgb(var(--color-black-rgb), 0.56);
}

:global(.ReactModal__Overlay--before-close) {
  background-color: transparent;
}

.modal {
  max-width: 394px;
  outline: none;
  overflow: hidden;
  text-align: center;
}

.wrapper {
  position: relative;
}

:global(.ReactModal__Content--after-open) {
  transition: opacity 0.15s ease-out 0.15s;
  opacity: 1;
}

.default :global(.ReactModal__Content--before-close) {
  transition: opacity 0.15s ease-out;
  opacity: 0;
}

.close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 4.4rem;
  padding: 0;
  position: absolute;
  right: calc(0px - var(--spacing-level-3));
  top: -12px;
  width: 4.4rem;
}

.title {
  padding: 0 var(--spacing-level-5);
  text-align: center;
}

.sideRight {
  justify-content: flex-end;
  padding: 0;

  .modal {
    height: 100vh;
    background-color: #FFF;
    transition: transform 0.5s linear, visibility 0.5s linear;
    animation-name: slideOpen;
    animation-duration: 0.5s;
    text-align: left;
    
    @media (--under-m-breakpoint) {
      transition: transform 0.5s linear, visibility 0.5s linear;
      animation-name: slideUp;
      animation-duration: 0.5s;
      height: 100%;
      min-width: 100%;
      top: 50px;
    }
  }
}

.bottomSheet :global(.ReactModal__Content--before-close)  {
  transition: transform 0.25s ease-out;
  transform: translateY(120%);
}

.bottomSheet {
  padding: 0;

  .modal {
    transition: transform 0.25s linear, visibility 0.25s linear;
    animation-name: slideUp;
    animation-duration: 0.25s;
    height: auto;
    max-height: calc(100% - 160px);
    min-width: 100%;
    bottom: 0;
    position: fixed;
    text-align: left;
    overflow-y: scroll;
    overscroll-behavior-y: none;
  }

  .wrapper {
    position: fixed;
    width: 100%;
    z-index: 1;
    background-color: var(--color-white);
    height: 24px;
  }

  .close {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .closeDark {
      background-color: var(--color-black);
  }

  .closeHandle {
    height: 4px;
    width: 40px;
    border-radius: 4px;
    background-color: var(--color-silver);
    display: block;
  }

  .closeHandleDark {
      background-color: #3D3D3D;
  }
}

/* Dark theme */
.overlay.dark {
  div {
    div {
      div {
        div:last-of-type {
            background-color: var(--color-dark-layer-1);
        }
      }
    }
  }
}

@keyframes slideOpen {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
