@import url("../../../../../styles/_variables.css");

:root {
  --attribute-spacing: var(--spacing-level-4);
  --attribute-border-width: 1px;
}

.attributesWrapper {
  overflow: hidden;
  padding-top: var(--spacing-level-2);
}

.attributes {
  display: table;
}

.attribute {
  display: table-row;
  padding-right: var(--spacing-level-3);
}

.attribute span {
  display: table-cell;
}

.label {
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 2;
  padding-right: var(--spacing-level-2);
  text-transform: uppercase;
}

@media (--xs-breakpoint) {
  .attributes {
    margin-left: calc(0px - (var(--attribute-border-width) + var(--attribute-spacing)));
  }

  .attribute {
    display: inline-block;
    padding-left: var(--attribute-spacing);
    border-left: var(--attribute-border-width) solid var(--color-silver);
  }
}

@media (--l-breakpoint) {
  .attribute {
    padding-right: var(--attribute-spacing);
  }
}
