@import url("../../../styles/_variables.css");

.premierIconWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2rem;
  position: relative;
  left: 0;
  right: 0;
  top: 55vh;
}

.loadingMessage {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  text-align: center;
}
