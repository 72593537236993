@import url("../../styles/_variables.css");

.table {
  width: 100%;
}

.cell {
  line-height: 1.71;
  padding-bottom: var(--spacing-level-5);
  padding-top: var(--spacing-level-2);
  text-transform: uppercase;

  @media (--l-breakpoint) {
    padding-bottom: var(--spacing-level-6);
  }
}

.returns .cell {
  padding-top: var(--spacing-level-6);
  padding-bottom: 0;
}

.row:first-child .cell {
  padding-top: 0;
}

.cell:last-child:not(:first-child) {
  text-align: right;
}

.footer .row .cell {
  font-size: var(--h3-size);
  letter-spacing: var(--h3-spacing);
  line-height: var(--h3-line-height);
  font-weight: bold;
  padding-bottom: 0;
  padding-top: var(--spacing-level-5);

  @media (--s-breakpoint) {
    padding-top: var(--spacing-level-6);
  }
}

.cell:last-child:not(:first-child) span {
  white-space: nowrap;
}

.returns .row:first-child .cell {
  padding-top: var(--spacing-level-1);
}

.returns .row:last-child .cell {
  padding-bottom: var(--spacing-level-5);
}
