@import url("../../../styles/_variables.css");

:root {
  --london1-font-size: 18px;
  --london1-line-height: 24px;
  --london1-letter-spacing: 0.8px;
  --london1-font-weight: 700;
  --barnsley4-font-size: 28px;
  --barnsley4-line-height: 36px;
  --barnsley4-letter-spacing: 1.2px;
  --barnsley4-font-weight: 700;
}

.title {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  text-transform: uppercase;
  font-size: var(--london1-font-size);
  line-height: var(--london1-line-height);
  letter-spacing: var(--london1-letter-spacing);
  font-weight: var(--london1-font-weight);
  width: 100%;
  padding: 0 var(--spacing-level-10);
}

@media (--s-breakpoint) {
  .title {
    padding: 0;
  }
}

@media (--m-breakpoint) {
  .title {
    text-align: left;
    text-transform: uppercase;

    &.primary {
      text-transform: uppercase;
      font-size: var(--barnsley4-font-size);
      line-height: var(--barnsley4-line-height);
      letter-spacing: var(--barnsley4-letter-spacing);
      font-weight: var(--barnsley4-font-weight);
    }

    &.hasDescription {
      margin-bottom: var(--spacing-level-2);
    }
  }

  .alignCenter {
    text-align: center;
  }
}
