@import url("../../styles/_variables.css");

.label {
  display: block;
  max-width: var(--field-max-width);
}

.notVisible {
  display: none;
}

.hasMargin {
  margin-top: var(--spacing-level-6);
}

.hasSmallMargin {
  margin-top: var(--spacing-level-3);
}

.hasError input,
.hasError select {
  border-color: var(--color-lipstick-red);
}

.inputWrapper {
  display: block;
  line-height: 0;
}

@media (--m-breakpoint) {
  .hasMargin {
    margin-top: var(--spacing-level-7);
  }
}

@media (--l-breakpoint) {
  .hasMargin {
    margin-top: var(--spacing-level-8);
  }
}
