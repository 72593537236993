@import url("../../styles/_variables.css");

.container {
  position: relative;
  flex: auto;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./white-noise.svg");
}

.greeting {
  position: absolute;
  top: 68px;
  left: 33px;
  display: flex;
}

.heroContainer {
  flex-direction: column;
  align-items: flex-start;
}

.heroText {
  background-color: var(--color-black);
  color: var(--color-white);
  line-height: 2;
  padding: 0 var(--spacing-level-3);
  margin-bottom: var(--spacing-level-1);
}
