@import url("../../styles/_variables.css");

:root {
  --default-link-spacing: var(--spacing-level-5);
  --m-link-spacing: var(--spacing-level-6);
  --l-link-spacing: var(--spacing-level-7);
}

.linkListContainer {
  position: relative;
}

.linkIcon {
  align-items: center;
  bottom: 0;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
}

.linkHorizonalRule {
  width: var(--full-width);
  position: absolute;
  bottom: 0;
}
