@import url("../../../styles/_variables.css");

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: var(--spacing-level-3);
}

.container > img {
  max-width: 100%;
}

@media (--xs-breakpoint) {
  .container > img {
    max-width: 500px;
  }
}
