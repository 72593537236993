@import url("../../styles/_variables.css");

.contentWrapper {
  overflow: hidden;
}

.contentList li,
.contentList p {
  margin-bottom: var(--spacing-level-6);
}

.contentFooter {
  width: 100%;
  text-align: right;
  margin: var(--spacing-level-6) 0 0;
}

.contentFooter.hasContent {
  margin: var(--spacing-level-6) 0;
  display: block;
}

.footerLink {
  display: block;
  font-size: var(--body-size);
  letter-spacing: var(--body-spacing);
  line-height: var(--body-line-height);
  padding-bottom: var(--spacing-level-4);
  text-decoration: underline;

  &:last-child {
    padding-bottom: 0;
  }
}

.title {
  padding-bottom: var(--spacing-level-3);
}

@media (--s-breakpoint) {
  .title {
    padding-bottom: var(--spacing-level-5);
  }
}

@media (--l-breakpoint) {
  .contentFooter {
    margin: var(--spacing-level-8) 0 0;
  }

  .contentFooter.hasContent {
    margin: var(--spacing-level-8) 0;
  }

  .footerLink {
    padding-top: 0;
    padding-bottom: var(--spacing-level-3);
  }
}
