@import url("../../../styles/_variables.css");

@keyframes colorChange {
  0% {color: var(--color-full-black);}
  50% {color: var(--color-black);}
  100%{color:var(--color-white);}
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cardBorder {
  border: 1px solid var(--asos-grey-00);
}

.wrapper {
  width: 356px;
  max-width: 100vw;
  height: 210px;
  border-radius: var(--spacing-level-2);
  box-shadow: 0 3.97px 7.95px 0 #00000040;
  padding: var(--spacing-level-3);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: var(--h2-size);
    line-height: var(--h2-size);
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 6px;
    color: var(--color-full-black);
    letter-spacing: -0.7px;
  }

  h1 {
    background-color: var(--color-full-black);
    padding: var(--spacing-level-1) var(--spacing-level-2);
    color: var(--color-white);
    text-transform: uppercase;
    font-weight: 500;
    width: fit-content;
    font-size: 4.2rem;
    line-height: 3.2rem;
    letter-spacing: -0.7px;
  }

  .charms {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    animation: fadeIn 0.6s ease;
    height: 210px;
  }

  @media (--under-xs-breakpoint) {
    margin: auto;
    min-width: 100%;
  }
}

.tier1 {
  background-color: var(--asos-world-light-violet);

  .tierLabels .tier1Label {
    color: var(--color-white);
    animation: colorChange 500ms ease-in;
  }

  @media (prefers-reduced-motion) {
    .tierLabels .tier1Label {
      animation: none;
    }
  }
}

.tier2 {
  background-color: var(--asos-world-mid-violet);

  .tierLabels .tier1Label, .tierLabels .tier2Label {
    color: var(--color-white);
    animation: colorChange 1000ms ease-in;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  .statusBarContainer { 
    div {
      transition: width 1500ms ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  }
}

.tier3 {
  background-color: var(--asos-world-violet);

  h2 {
    color: var(--color-white);
  }

  .tierLabels .tier1Label, .tierLabels .tier2Label, .tierLabels .tier3Label {
    color: var(--color-white);
    animation: colorChange 1000ms ease-in;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  .statusBarContainer {
    div {
      transition: width 2000ms ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  }
}

.tier4 {
  background-color: #000;

  h2 {
    color: var(--color-white);
  }

  h1 {
    background-color: var(--color-white);
    color: var(--color-full-black);
  }

  .statusBarContainer {
    div {
      transition: width 2500ms ease-in-out;

      @media (prefers-reduced-motion) {
        transition: none;
      }
    }

    div:first-of-type {
      background-color: var(--asos-world-light-violet);
    }
  }

  .tierLabels {
    .tier1Label, .tier2Label, .tier3Label, .tier4Label {
      color: var(--color-white);
      animation: colorChange 800ms ease-in;

      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  }
}

/* stylelint-disable no-descending-specificity */
.statusBarContainer {
  position: relative;

  div:first-of-type {
    background-color: rgba(255, 255, 255, 60%);
    height: 1.4rem;
    border: none;
    border-radius: var(--spacing-level-2);
    margin-top: 0;

    div {
      transition: width 1000ms ease-in-out;
      
      @media (prefers-reduced-motion) {
        transition: none;
      }
    }
  }
  /* stylelint-enable no-descending-specificity */

  .tierLabelsWrapper {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .tierLabels {
    background-color: unset !important;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--spacing-level-2);
    font-size: var(--small-interactive-size);
    line-height: var(--small-interactive-line-height);
    font-weight: 600;
    color: var(--color-full-black);
  }
}
