@import url("../../styles/_variables.css");

:root {
  --radio-border-width: 2px;
  --radio-size: 22px;
  --radio-spacing: calc(var(--radio-size) + var(--spacing-level-5));
  --min-height: 36px;
}

.label {
  color: var(--asos-grey-70);
  line-height: var(--body-line-height);
}

.inputWrapper {
  min-height: var(--radio-size);
  position: relative;
}

.styledInput {
  cursor: pointer;
  min-height: var(--min-height);
  padding: calc((var(--min-height) - (2 * var(--radio-border-width)) - var(--radio-size)) / 2) 0;
  position: relative;
}

.vertical .styledInput {
  padding: var(--spacing-level-3) 0;
}

.radioInput {
  position: absolute;
  opacity: 0;
  padding: 0;
  margin: 0;
}

.radioImage,
.radioInput {
  display: inline-block;
  height: var(--radio-size);
  width: var(--radio-size);
}

@media (--l-breakpoint) {
  .vertical .styledInput {
    padding: var(--spacing-level-7) 0;
  }
}

.horizontal.inputWrapper,
.horizontal .styledInput,
.horizontal .label {
  display: inline-block;
}

.vertical.inputWrapper,
.vertical .styledInput,
.vertical .label {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.vertical.inputWrapper {
  min-height: 72px;
}

.horizontal .label {
  margin-left: var(--radio-spacing);
}

.vertical .label {
  margin-right: var(--radio-spacing);
}

.radioImageWrapper,
.radioInput {
  display: inline-block;
  height: var(--radio-size);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.horizontal .radioImageWrapper,
.horizontal .radioInput {
  left: 0;
}

.vertical .radioImageWrapper,
.vertical .radioInput {
  right: 0;
}

.radioImage {
  border-radius: 50%;
  border: var(--radio-border-width) solid var(--asos-grey-70);
  position: relative;
}

.radioInput:checked + .styledInput .radioImage:after {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--color-black);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radioInput:focus + .styledInput .radioImage {
  border-color: var(--color-accessible-contrast-blue);
  border-width: 2px;
}

.radioInput:active + .styledInput .radioImage {
  background-color: var(--color-off-white);
}

.radioInput:checked + .styledInput .label {
  color: var(--color-black);
}
