@import url("../../../styles/_variables.css");

:root {
  --close-icon-cross-size: 14px;
  --close-icon-width: 32px;
  --close-icon-height: 44px;
}

.item {
  position: relative;
  width: 100%;
}

.item:after,
.item:before {
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 12px;
  left: calc(0px - var(--spacing-level-2));
  position: absolute;
  right: calc(0px - var(--spacing-level-2));
  transition: background-position 1s ease;
  transition: border-color 1s ease;
  z-index: 1;
}

.item:before {
  background-image: linear-gradient(to top, var(--color-silver) 0%, var(--color-off-white) 3%, var(--color-white) 50%);
  background-position: 0 10px;
  border-bottom: 1px solid var(--color-silver);
  bottom: auto;
  top: -12px;
}

.item:after {
  background-image: linear-gradient(to bottom, var(--color-silver) 0%, var(--color-off-white) 3%, var(--color-white) 50%);
  background-position: 0 -10px;
  border-top: 1px solid var(--color-silver);
  bottom: -11px;
  top: auto;
}

.item.opened:after,
.item.opened:before {
  z-index: 2;
  background-position: 0 0;
  transition: border-color 0.2s ease;
  left: calc(0px - var(--spacing-level-4));
  right: calc(0px - var(--spacing-level-4));
}

.item.opened:after {
  border-top: 1px solid var(--color-white);
}

.item.opened:before {
  border-bottom: 1px solid var(--color-white);
}

.slim.item:after,
.slim.item:before {
  background-image: none;
  border: none;
}

@media (--s-breakpoint) {
  .item:after,
  .item:before {
    left: calc(0px - var(--spacing-level-3));
    right: calc(0px - var(--spacing-level-3));
  }

  .item.opened:after,
  .item.opened:before {
    left: calc(0px - var(--spacing-level-6));
    right: calc(0px - var(--spacing-level-6));
  }
}

@media (--l-breakpoint) {
  .item:after,
  .item:before {
    left: calc(0px - var(--spacing-level-4));
    right: calc(0px - var(--spacing-level-4));
  }

  .item.opened:after,
  .item.opened:before {
    left: calc(0px - var(--spacing-level-8));
    right: calc(0px - var(--spacing-level-8));
  }
}

.title {
  display: inline-block;
  text-align: left;
  vertical-align: bottom;
  width: 100%;
}

.opened .title {
  font-weight: bold;
}

.verticalSpacing {
  line-height: var(--small-interactive-line-height);
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 50%;
  height: var(--close-icon-height);
  width: var(--close-icon-width);
  transform: translateY(-50%);
  font-size: 0;
  color: transparent;

  &:before,
  &:after {
    backface-visibility: hidden;
    background-color: var(--color-black);
    color: transparent;
    content: "-";
    display: block;
    height: 4px;
    left: 8px;
    perspective: 1000;
    position: absolute;
    top: 21px;
    transition: transform ease-out 0.5s;
    width: 28px;
    will-change: transform;
  }

  &:before {
    animation: close-cross-animation-before 0.7s ease-out;
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }

  &:after {
    animation: close-cross-animation-after 0.7s ease-out;
    transform: rotate(-90.01deg) scale(0.5, 0.5);
  }
}

.opened .closeIcon {
  &:before {
    animation: open-cross-animation-before 0.7s ease-out forwards;
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }

  &:after {
    animation: open-cross-animation-after 0.7s ease-out forwards;
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }
}

.toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  text-align: left;
  width: 100%;

  &:focus {
    outline-offset: -4px;
  }
}

/* START: CSS Transitions */

.contentEnter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.contentEnter.contentEnterActive {
  max-height: 900px;
  opacity: 1;
  transition: max-height 0.5s, opacity 0.8s ease;
}

.contentLeave {
  max-height: 900px;
  opacity: 1;
  transition: max-height 0.5s, opacity 0.8s ease;
}

.contentLeave.contentLeaveActive {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

@keyframes open-cross-animation-before {
  0% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }

  50% {
    transform: rotate(45deg) scale(0.5, 0.5);
  }

  100% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }
}

@keyframes open-cross-animation-after {
  0% {
    transform: rotate(-90.01deg) scale(0.5, 0.5);
  }

  50% {
    transform: rotate(-45deg) scale(0.5, 0.5);
  }

  100% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }
}

@keyframes close-cross-animation-before {
  0% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }

  50% {
    transform: rotate(45deg) scale(0.5, 0.5);
  }

  100% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }
}

@keyframes close-cross-animation-after {
  0% {
    transform: rotate(0.01deg) scale(0.5, 0.5);
  }

  50% {
    transform: rotate(-45deg) scale(0.5, 0.5);
  }

  100% {
    transform: rotate(-90.01deg) scale(0.5, 0.5);
  }
}

/* END: CSS Transitions */
