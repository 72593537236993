@import url("../../styles/_variables.css");

:root {
  --badge-size: 1.6rem;
}

.badge {
  background: var(--color-bright-red);
  border-radius: 50%;
  color: var(--color-white);
  font-size: calc(var(--badge-size) / 2);
  font-weight: bold;
  height: var(--badge-size);
  line-height: var(--badge-size);
  width: var(--badge-size);
}

/* Chrome-Only CSS hack due to position rendering bug */
@media (--chrome-only) {
  .badge {
    text-indent: 2px;
  }
}
