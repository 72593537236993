@import url("reset-css");
@import url("./styles/_fonts.css");
@import url("./styles/_variables.css");

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 0.625em; /* e.g. 16px * 0.625 = 10px baseline font size for easy rem calculation */
}

body,
button,
input,
input[type="button"],
input[type="email"],
input[type="file"],
input[type="password"],
input[type="reset"],
input[type="search"],
input[type="submit"],
select,
textarea {
  color: var(--color-black);
  font-family: var(--font-stack);
  font-size: var(--body-size);
  font-weight: normal;
  letter-spacing: var(--body-spacing);
  margin: 0;
}

/* `display: none` won't work for this pesudo-element, so change size instead http://bit.ly/2DJ2dt0 */
::-ms-clear {
  height: 0;
  opacity: 0;
  width: 0;
}

/* Can't combine with the MS rule above, or the style override won't work! */
input[type="search"]::-webkit-search-cancel-button {
  appearance: none;
  display: none;
}

body {
  background-color: var(--color-gallery-gray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
html,
body {
  width: 100%;
  overflow-x: hidden;
}

@media (--under-m-breakpoint) {
  html:has(:global([data-auto-id*="loyalty-hub"])) {
    overscroll-behavior: none;
  }
}

a {
  text-decoration: none;
  color: var(--color-black);
}

a:hover {
  text-decoration: underline;
}

.darkWrapper {
  background-color: var(--asos-full-black);
  color: var(--asos-white);
}

.darkModeAsosLayer1 {
    background-color: var(--color-dark-layer-1) !important;
    color: var(--asos-grey-40)  !important;
 }

.darkModeAsosLayer2 {
    background-color: var(--color-dark-layer-2) !important;
    color: var(--asos-grey-40)  !important;
 }
