@import url("../../styles/_variables.css");

:root {
  --icon-spacing: var(--spacing-level-7);
  --default-link-spacing: var(--spacing-level-5);
  --s-link-spacing: var(--spacing-level-6);
  --l-link-spacing: var(--spacing-level-8);
}

.wrapper {
  padding: var(--default-link-spacing) var(--icon-spacing) var(--default-link-spacing) 0;

  @media (--s-breakpoint) {
    padding: var(--s-link-spacing) var(--icon-spacing) var(--s-link-spacing) 0;
  }

  @media (--l-breakpoint) {
    padding: var(--l-link-spacing) var(--icon-spacing) var(--l-link-spacing) 0;
  }

  &.slim {
    padding-bottom: var(--spacing-level-3);
    padding-top: var(--spacing-level-3);
  }
}
