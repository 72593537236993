@import url("../../../styles/_variables.css");

.buttonWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

@media (--m-breakpoint) {
  .buttonWrapper {
    padding-top: var(--spacing-level-5);
  }
}
