@import url("../../styles/_variables.css");

.container {
  margin-left: calc(var(--medium-half-gutter) * -1);
  margin-right: calc(var(--medium-half-gutter) * -1);

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.col {
  float: left;
  padding-left: var(--medium-half-gutter);
  padding-right: var(--medium-half-gutter);
}

.flex {
  display: flex;
}

.hideSmallOnly {
  display: none;

  @media (--m-breakpoint) {
    display: block;

    &.flex {
      display: flex;
    }
  }
}

.small-1 {
  width: calc(var(--full-width) * 1 / var(--total-columns));
}

.small-2 {
  width: calc(var(--full-width) * 2 / var(--total-columns));
}

.small-3 {
  width: calc(var(--full-width) * 3 / var(--total-columns));
}

.small-4 {
  width: calc(var(--full-width) * 4 / var(--total-columns));
}

.small-5 {
  width: calc(var(--full-width) * 5 / var(--total-columns));
}

.small-6 {
  width: calc(var(--full-width) * 6 / var(--total-columns));
}

.small-7 {
  width: calc(var(--full-width) * 7 / var(--total-columns));
}

.small-8 {
  width: calc(var(--full-width) * 8 / var(--total-columns));
}

.small-9 {
  width: calc(var(--full-width) * 9 / var(--total-columns));
}

.small-10 {
  width: calc(var(--full-width) * 10 / var(--total-columns));
}

.small-11 {
  width: calc(var(--full-width) * 11 / var(--total-columns));
}

.small-12 {
  width: var(--full-width);
}

@media (--s-breakpoint) {
  .medium-1 {
    width: calc(var(--full-width) * 1 / var(--total-columns));
  }

  .medium-2 {
    width: calc(var(--full-width) * 2 / var(--total-columns));
  }

  .medium-3 {
    width: calc(var(--full-width) * 3 / var(--total-columns));
  }

  .medium-4 {
    width: calc(var(--full-width) * 4 / var(--total-columns));
  }

  .medium-5 {
    width: calc(var(--full-width) * 5 / var(--total-columns));
  }

  .medium-6 {
    width: calc(var(--full-width) * 6 / var(--total-columns));
  }

  .medium-7 {
    width: calc(var(--full-width) * 7 / var(--total-columns));
  }

  .medium-8 {
    width: calc(var(--full-width) * 8 / var(--total-columns));
  }

  .medium-9 {
    width: calc(var(--full-width) * 9 / var(--total-columns));
  }

  .medium-10 {
    width: calc(var(--full-width) * 10 / var(--total-columns));
  }

  .medium-11 {
    width: calc(var(--full-width) * 11 / var(--total-columns));
  }

  .medium-12 {
    width: var(--full-width);
  }
}

@media (--m-breakpoint) {
  .container {
    margin-left: calc(var(--large-half-gutter) * -1);
    margin-right: calc(var(--large-half-gutter) * -1);
  }

  .col {
    padding-left: var(--large-half-gutter);
    padding-right: var(--large-half-gutter);
  }

  .large-1 {
    width: calc(var(--full-width) * 1 / var(--total-columns));
  }

  .large-2 {
    width: calc(var(--full-width) * 2 / var(--total-columns));
  }

  .large-3 {
    width: calc(var(--full-width) * 3 / var(--total-columns));
  }

  .large-4 {
    width: calc(var(--full-width) * 4 / var(--total-columns));
  }

  .large-5 {
    width: calc(var(--full-width) * 5 / var(--total-columns));
  }

  .large-6 {
    width: calc(var(--full-width) * 6 / var(--total-columns));
  }

  .large-7 {
    width: calc(var(--full-width) * 7 / var(--total-columns));
  }

  .large-8 {
    width: calc(var(--full-width) * 8 / var(--total-columns));
  }

  .large-9 {
    width: calc(var(--full-width) * 9 / var(--total-columns));
  }

  .large-10 {
    width: calc(var(--full-width) * 10 / var(--total-columns));
  }

  .large-11 {
    width: calc(var(--full-width) * 11 / var(--total-columns));
  }

  .large-12 {
    width: var(--full-width);
  }
}
