@import url("../../../../../../styles/_variables.css");

.lineBreak {
  padding: var(--spacing-level-4) 0 var(--spacing-level-5);
}

.voucherRestriction {
  position: relative;
  padding-left: var(--spacing-level-7);
}

.voucherRestrictionMessageIcon {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
}

.voucherRestrictionLink {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  border-bottom: 1px solid var(--asos-grey-70);
  cursor: pointer;
}

.voucherRestrictionMessage,
.voucherRestrictionLink {
  display: inline;
  color: var(--asos-grey-70);
  vertical-align: text-top;
  font-size: var(--body-size);
  line-height: var(--body-line-height);
}

.notificationWrapper {
  margin: var(--spacing-level-2) 0 calc(var(--spacing-level-2) * -1);
}
