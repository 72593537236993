@import url("../../styles/_variables.css");

.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: calc(100vw - 100%);
}

.contentContainer {
  position: relative;
}

.inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: 1px;
}

.contentWrapperInner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: var(--spacing-level-8) auto 0 auto;
  padding-bottom: var(--spacing-level-3);
  position: relative;
}

.notificationRowWrapper {
  bottom: 0;
  position: absolute;
  width: 100%;
}

.sticky {
  z-index: 1;
}

/* Lifted so that Notifications will look like they appear from behind it  */
.footerWrapper {
  z-index: 1;
  align-self: flex-end;
  vertical-align: bottom;
  width: 100%;
  min-height: 1px;
}

@media (--under-s-breakpoint ) {
  .noFooter {
    display: none;
  }
}

@media (--under-m-breakpoint ) {
  .layoutLoyaltyHub {
    overflow: hidden;
  }
}

@media (--xs-breakpoint) {
  .contentWrapperInner {
    padding-bottom: var(--spacing-level-5);
  }
}
