@import url("../../styles/_variables.css");

.leave {
  opacity: 1;
}

.leave.leaveActive {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loadingContainer {
  position: relative;
  min-height: 500px;
}

.small {
  min-height: 300px;
}

.fullScreen {
  height: 100vh;
}

.loadingScreen {
  /* Loading opacity set as variable in order to prevent comma separating colour vals and a val from being deleted. */
  /* stylelint-disable-next-line value-list-comma-space-after, value-list-comma-space-before */
  --loading-opacity: ,0.85;

  background: rgb(var(--color-white-rgb), var(--loading-opacity));
  position: absolute;
  inset: 0 0 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@media (--xs-breakpoint) {
  .loadingScreen {
    margin: 0 var(--spacing-level-4);
  }
}

@media (--s-breakpoint) {
  .loadingScreen {
    margin: 0 var(--spacing-level-6);
  }
}

@media (--m-breakpoint) {
  .loadingScreen {
    margin: 0;
  }
}
