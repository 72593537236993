@import url("../../styles/_variables.css");

:root {
  --productHeight: calc(100% * var(--product-image-ratio));
  --voucherHeight: calc(100% * var(--voucher-image-ratio));
  --default-layout-margin: 6px;
  --reduced-layout-margin: var(--spacing-level-2);
}

.items {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}

.column {
  flex: 1 1 auto;
  align-self: auto;
  margin: 0 var(--default-layout-margin);
}

.column:first-child {
  margin-left: 0;
}

.column:last-child {
  margin-right: 0;
}

.gaps {
  overflow: hidden;
}

.centerOnSmall.gaps.even .column {
  transform: translateX(50%) translateX(calc(var(--default-layout-margin) * 2));
}

.centerOnSmall.gaps.odd .column {
  transform: translateX(100%) translateX(calc(var(--default-layout-margin) * 2));
}

@media (--xs-breakpoint) {
  .column {
    margin: 0 var(--reduced-layout-margin);
  }
}

@media (--s-breakpoint) {
  .column {
    margin: 0 var(--default-layout-margin);
  }

  .centerOnSmall.gaps.even .column,
  .centerOnSmall.gaps.odd .column {
    transform: none;
  }
}

@media (--m-breakpoint) {
  .column {
    margin: 0 var(--reduced-layout-margin);
  }
}

@media (--orders-list-l-breakpoint) {
  .column {
    margin: 0 var(--default-layout-margin);
  }
}

.columnInner {
  position: relative;
  padding-top: var(--voucherHeight);
}

.columnInner.product {
  padding-top: var(--productHeight);
}
