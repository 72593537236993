@import url("../../../../styles/_variables.css");

.placeholder {
  background: var(--color-gallery-gray);
  animation: var(--full-page-loading-animation);
}

.circlePlaceholder {
  border-radius: 100%;
  width: 100%;
  height: 100%;
  margin: auto;
}

.circlePlaceholder.small {
  height: var(--body-line-height);
  width: var(--body-line-height);
}
