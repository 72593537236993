@import url("../../styles/_variables.css");

.sectionDivider {
  height: 50px;
  line-height: 50px;
  position: relative;
  text-align: center;
  width: 100%;
}

.sectionDivider:before {
  background-color: var(--color-silver);
  content: "";
  display: block;
  height: 1px;
  left: -8px;
  position: absolute;
  right: -8px;
  top: 50%;
}

@media (--s-breakpoint) {
  .sectionDivider:before {
    left: -12px;
    right: -12px;
  }
}

@media (--l-breakpoint) {
  .sectionDivider:before {
    left: calc(0px - var(--spacing-level-4));
    right: calc(0px - var(--spacing-level-4));
  }
}

.sectionDividerText {
  color: var(--asos-grey-70);
  font-size: var(--h4-size);
  letter-spacing: var(--h4-spacing);
  line-height: var(--h4-line-height);
  font-weight: bold;
  background-color: var(--color-white);
  display: inline-block;
  padding: 0 10px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
