@import url("../../../styles/_variables.css");

.userInitials {
  color: var(--color-white);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.wrapper {
  background: var(--color-black);
  border: 6px solid transparent;
  border-radius: 50%;
  height: var(--nav-circle-size-mobile);
  letter-spacing: 0.28rem;
  line-height: calc(var(--nav-circle-size-mobile) - 8px);
  margin: auto;
  margin-top: calc((var(--nav-circle-size-mobile) / 2) * -1);
  text-align: center;
  text-indent: 0.4rem;
  vertical-align: middle;
  width: var(--nav-circle-size-mobile);
}

@media (--m-breakpoint) {
  .wrapper {
    border: none;
    display: inline-block;
    height: var(--nav-circle-size-desktop);
    line-height: var(--nav-circle-size-desktop);
    margin: 0 0 0 -10px;
    min-width: var(--nav-circle-size-desktop);
    width: var(--nav-circle-size-desktop);
  }
}
