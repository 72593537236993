@import url("../../../../styles/_variables.css");

.center {
  text-align: center;
}

.parcelDialog {
  letter-spacing: 0.03rem;
  line-height: 2.2rem;
}

.modalLink {
  display: inline-block;
  margin: var(--spacing-level-4) 0;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
  text-decoration: none;
  color: var(--asos-grey-70);
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  line-height: 1.4rem;
  font-weight: 700;
  padding-bottom: calc(3 * 4px);
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

.modalLink:hover {
  text-decoration: underline;
}

.returnsWarningBold {
  font-weight: bold;
}
