@import url("../../../styles/_variables.css");

:root {
  --icon-size: 20px;
}

.returnsInfo {
  color: #000;
  display: inline-block;
  width: 95%;
}

.returnsInfo span {
  background-image: url("../../../../src/components/picture-icon/img/open-new-window.svg");
  background-position: 99% 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  padding-right: 2.5rem;
}

.headingWrapper {
  padding-bottom: var(--spacing-level-4);
  text-transform: uppercase;

  @media (--s-breakpoint) {
    padding-bottom: var(--spacing-level-6);
  }
}

.returnsInfo span a {
  text-decoration: underline;
}

.returnsInfo span a:hover {
  text-decoration: none;
}

.returnStep:not(:first-child) {
  margin-top: var(--spacing-level-4);
}

.infoBlock {
  margin-bottom: var(--spacing-level-6);
}

.locationInfo {
  color: var(--color-dark-gray);
  margin-bottom: var(--spacing-level-6);
}

.returnsPolicyInfo {
  color: var(--color-med-gray);
  margin-bottom: var(--spacing-level-6);
  margin-top: var(--spacing-level-2);
}

/* stylelint-disable-next-line no-descending-specificity */
.returnsPolicyInfo a {
  color: var(--color-med-gray);
  text-decoration: underline;
}

/* stylelint-disable-next-line no-descending-specificity */
.returnsPolicyInfo a:hover {
  text-decoration: none;
}

.refundAmount {
  margin-bottom: var(--spacing-level-4);
}

.refundNote {
  color: var(--color-dark-gray);
}

.totalSalesTax {
  display: block;
  color: var(--asos-grey-70);
}

.provider {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-level-3);
}

.providerIcon {
  max-height: var(--icon-size);
  width: var(--icon-size);
}

.providerName {
  font-size: var(--small-size);
  letter-spacing: var(--small-spacing);
  line-height: var(--icon-size);
  margin-left: var(--spacing-level-3);
}

.images {
  margin-top: var(--spacing-level-3);
  max-width: 330px;
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
