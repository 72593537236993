@import url("../../styles/_variables.css");

@value defaultImageMaxSize: 220px;
@value smallImageMaxSize: 195px;

.imageWrapper {
  position: relative;
  padding-left: 100%;
  padding-top: calc(var(--product-image-ratio) * 100%);
  overflow: hidden;
}

.voucherWrapper {
  padding-top: calc(var(--voucher-image-ratio) * 100%);
}

.quantity {
  background-color: var(--color-white);
  min-width: 2.6rem;
  padding: var(--spacing-level-1);
  position: absolute;
  right: 8px;
  text-align: center;
  top: 0;
  z-index: 1;
}
