@import url("../../../../../../styles/_variables.css");

.lineBreak {
  padding: var(--spacing-level-4) 0 var(--spacing-level-5);
}

.voucherReturnsLink {
  background: none;
  border: none;
  border-bottom: 1px solid var(--asos-grey-70);
  cursor: pointer;
  font: inherit;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding: 0;
  display: inline;
  color: var(--asos-grey-70);
  vertical-align: text-top;
  font-size: var(--body-size);
  line-height: var(--body-line-height);
}

.notificationWrapper {
  margin: var(--spacing-level-2) 0 calc(var(--spacing-level-2) * -1);
}
