@import url("../../styles/_variables.css");

.iconContainer {
  float: left;
  margin-right: var(--spacing-level-4);
  margin-top: 2px;
}

.selectContainer {
  overflow: hidden;
  width: auto;
}

.error {
  background-color: var(--color-light-lipstick-red);
  border: 1px solid var(--color-lipstick-red);
  line-height: 2rem;
  max-width: var(--field-max-width);
  padding: var(--spacing-level-3) 15px;
  position: relative;
  margin-top: var(--spacing-level-4);
  left: calc(var(--spacing-level-4) * -1);
}

.error:after,
.error:before {
  bottom: 100%;
  left: 26px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.error:after {
  border-bottom-color: var(--color-light-lipstick-red);
  border-width: 9px;
  margin-left: -9px;
}

.error:before {
  border-bottom-color: var(--color-lipstick-red);
  border-width: 10px;
  margin-left: -10px;
}
