.image {
  width: 100%;
  display: block;
  height: auto;
}

.imageResponsive {
  position: absolute;
  top: 0;
  left: 0;
}
