@import url("../../../styles/_variables.css");

.formFieldsWrapper {
  margin: 0 auto;
  max-width: var(--field-max-width);
}

.submitButtonWrapper {
  text-align: center;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
}

@media (--s-breakpoint) {
  .formFieldsWrapper {
    max-width: 100%;
  }

  .submitButtonWrapper {
    text-align: left;
  }

  .buttonWrapper {
    justify-content: left;
  }
}
