@import url("../../../../../styles/_variables.css");

:root {
  --image-width: 108px;
}

.wrapper {
  display: inline-block;
  width: 100%;
}

.image {
  float: left;
  padding-right: var(--spacing-level-3);
  margin-bottom: var(--spacing-level-4);
  width: var(--image-width);
}

.details {
  padding-bottom: var(--spacing-level-4);
}

.select {
  font-size: var(--body-size);
  clear: both;
}

@media (--xs-breakpoint) {
  .select {
    padding-left: var(--image-width);
    clear: none;
  }

  .image {
    margin-bottom: 0;
  }
}

@media (--s-breakpoint) {
  .image {
    padding-right: var(--spacing-level-5);
  }
}

@media (--l-breakpoint) {
  .image {
    padding-right: var(--spacing-level-6);
  }
}
