@import url("../../styles/_variables.css");

.label {
  display: inline-block;
  color: var(--asos-grey-70);
  font-size: var(--h4-size);
  text-transform: uppercase;
  letter-spacing: var(--h4-spacing);
  line-height: var(--h4-size);
  font-weight: 700;
  padding-bottom: var(--spacing-level-3);
}

.sameLine {
  display: inline;
}

.inline {
  padding-bottom: 0;
  margin-right: var(--spacing-level-2);
}

.black {
  color: var(--color-black);
}

.emphasis {
  font-size: var(--body-size);
  letter-spacing: var(--body-spacing);
  line-height: var(--body-size);
}
