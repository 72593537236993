@import url("../../../../styles/_variables.css");

.voucher {
  border: 1px solid var(--color-silver);
  box-shadow: var(--color-silver) 0 0 2px;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.07rem;
  line-height: 1rem;
  min-height: 100px;
  min-width: 160px;
  padding: var(--spacing-level-4);
  text-align: left;
  width: 100%;
}

.sender {
  text-transform: uppercase;
}

.from {
  color: var(--asos-grey-70);
}

.content {
  border-left: 2px solid var(--color-black);
  margin-top: var(--spacing-level-1);
  padding-left: var(--spacing-level-2);
}

.skeleton,
.code {
  margin-top: var(--spacing-level-1);
}

.content > :first-child {
  margin-top: 0;
}

.skeleton {
  background-color: var(--color-silver);
  display: block;
  height: 0.8rem;
}

.short {
  width: 52%;
}

.medium {
  width: 60%;
}

.long {
  width: 80%;
}

.code {
  border: 2px solid var(--color-lipstick-red);
  display: inline-block;
  font-size: 0.8rem;
  letter-spacing: 0.06rem;
  padding: calc(var(--spacing-level-1) / 2);
}

@media (--s-breakpoint) {
  .code {
    font-size: 0.9rem;
    letter-spacing: 0.08rem;
  }

  .skeleton,
  .code {
    margin-top: calc(var(--spacing-level-1) * 1.5);
  }

  .skeleton {
    height: 0.9rem;
  }
}

@media (--l-breakpoint) {
  .voucher {
    font-size: 1.2rem;
    letter-spacing: 0.15rem;
    padding: var(--spacing-level-5);
  }

  .content {
    margin-top: var(--spacing-level-2);
  }

  .skeleton,
  .code {
    margin-top: var(--spacing-level-2);
  }

  .code {
    font-size: 1.05rem;
    letter-spacing: 0.09rem;
  }

  .skeleton {
    height: 1.05rem;
  }
}
