@import url("../../styles/_variables.css");

.action {
  background: none;
  border: none;
  cursor: pointer;
  display: block;
  font-size: var(--small-interactive-size);
  font-weight: bold;
  height: 100%;
  letter-spacing: var(--small-interactive-spacing);
  line-height: var(--small-interactive-line-height);
  min-width: 0;
  padding: 0;
  text-transform: uppercase;
}
