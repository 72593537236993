@import url("../../../src/styles/_variables.css");

.icon {
  height: auto;
  width: auto;
}

.xtiny {
  width: 12px;
  max-height: 12px;
}

.tiny {
  width: 15px;
  max-height: 15px;
}

.small {
  width: var(--spacing-level-6);
  max-height: var(--spacing-level-6);
}

.large {
  min-width: 30px;
  max-height: 30px;
}

.xlarge {
  width: 88px;
  max-height: 88px;
}
