@import url("../../../styles/_variables.css");

.hidden {
  visibility: hidden;
  overflow: hidden;
  min-height: 1px;
  max-height: 500px; /* up to the footer ✨ */
}

.hidden.showSmallNavigationOnly {
  min-height: 1100px; /* height of navigation ✨ */
}

.main,
.main:focus {
  outline: none;
}

@media (--m-breakpoint) {
  .hidden,
  .hidden.showSmallNavigationOnly {
    min-height: 1px;
    max-height: 1050px; /* up to the footer ✨ */
  }
}
