@import url("../../../../styles/_variables.css");

.appPlaceholder {
  width: 100%;
  min-height: 400px;
  background: var(--color-gallery-gray);
}

.firstPlaceholder,
.secondPlaceholder,
.thirdPlaceholder,
.fourthPlaceholder {
  line-height: 1.2rem;
  margin-bottom: var(--spacing-level-3);
}

.mockTitlePlaceholder {
  line-height: 2.4rem;
  margin-bottom: var(--spacing-level-6);
  width: 50%;
}

.secondPlaceholder {
  width: 75%;
  opacity: 0.75;
}

.thirdPlaceholder {
  width: 85%;
  opacity: 0.5;
}

.fourthPlaceholder {
  width: 40%;
  opacity: 0.3;
}

.headerPlaceholder {
  line-height: 2rem;
}

.dummyPageHeader {
  margin-bottom: var(--spacing-level-3);
  padding: 13px 74px;
  position: relative;
}

.iconPlaceholder {
  display: inline-block;
  position: absolute;
  left: var(--spacing-level-4);
  top: 11px;
}

.dummyPageHeader,
.dummyPageContent {
  background: var(--color-white);
}

.dummyPageContent {
  height: 460px; /* lines up with the footer ✨ */
}

@media (--m-breakpoint) {
  .appPlaceholder {
    height: 990px; /* lines up perfectly with the loading navigation component ✨ */
    background: var(--color-off-white);
  }

  .dummyPageContent {
    height: auto;
  }

  .appPlaceholderContent {
    display: none;
  }
}

@media (--l-breakpoint) {
  .appPlaceholder {
    height: 1013px; /* lines up perfectly with the loading navigation component ✨ */
    background: var(--color-off-white);
  }
}
