@import url("../../styles/_variables.css");

.footer {
  background-color: var(--color-white);
  font-size: var(--body-size);
  line-height: var(--body-line-height);
  text-align: center;
  z-index: 10;
}

.linkWrapper {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.footerSection {
  text-align: center;
  display: inline-block;
}

.navLink {
  display: inline-block;
  padding: var(--spacing-level-4);
}

.navLink:focus {
  text-decoration: underline;
}

.copySection {
  display: block;
  padding: var(--spacing-level-4) 0;
}

.groupedLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (--s-breakpoint) {
  .groupedLinks {
    display: inline-block;
  }

  .linkWrapper {
    flex-direction: row;
  }
}

@media (--m-breakpoint) {
  .footer {
    text-align: left;
  }

  .copySection {
    float: right;
    text-align: right;
  }

  .homepageLink {
    padding-left: 0;
  }
}

@media (--l-breakpoint) {
  .footer {
    padding: 0 var(--spacing-level-4);
  }

  .linkWrapper {
    justify-content: space-between;
  }
}
