.wrapper {
  .text {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .loyaltyCtaPrimary {
    margin-top: var(--spacing-level-4);
    min-width: 100%;
  }

  .loyaltyCtaSecondary {
    margin-top: var(--spacing-level-4);
    min-width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  .iconWrapper {
    padding-bottom: var(--spacing-level-1);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    img{
      margin: var(--spacing-level-2) 0;
      padding-right: var(--spacing-level-3);
    }
  }

  .tierBadge {
    display: flex;
    align-items: center;

    img {
      height: 14px;
      margin: 0;
      padding-right: 0;
    }
    
    span {
      padding-left: var(--spacing-level-1);
      text-transform: uppercase;
      font-size: var(--small-interactive-size);
      line-height: var(--body-line-height);
      font-weight: 500;
      letter-spacing: -0.7px;
    }
  }
}

.optedOut {
  .pill {
    background-color: var(--color-cerulean-blue);
    color: var(--color-white);
    text-transform: uppercase;
    padding: 0.1rem 0.5rem;
    font-weight: bold;
    font-size: var( --h6-size);
    letter-spacing: 1px;
    line-height: 1.6rem;
    margin-bottom: var(--spacing-level-1);
    border-radius: var(--spacing-level-3);

    @media (--under-m-breakpoint) {
      font-size: var(--body-size);
      line-height: var(--small-line-height);
    }
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    
    @media (--under-m-breakpoint) {
      img {
        height: 26px;
      }
    }
  }

  .tsAndCsWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacing-level-2);
    font-size: var(--small-size);
  }

  .tsAndCs {
    border: unset;
    background: unset;
    text-transform: none;
    font-size: var(--small-size);
    font-weight: normal;
    text-decoration: underline;
    display: inline-block;
    width: fit-content;
    max-width: fit-content;
    min-width: fit-content;
    padding: 0 var(--spacing-level-1);
    margin: 0;

    &:hover, &:focus {
      background-color: unset;
      text-decoration: underline;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .loyaltyCtaSecondary {
    margin-top: var(--spacing-level-2);
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.firstView {
  animation: fadeIn 1.2s;
}

.optInError {
  display: none;
  animation: fadeOut 0.6s;
}

.optInErrorActive {
  display: block;
  animation: fadeIn 0.6s;
  padding-top: var(--spacing-level-2);
}