@import url("../../../../styles/_variables.css");

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wrapper {
  height: 300px;
  position: relative;
  animation: fadeIn 0.6s ease;

  @media (--under-m-breakpoint) {
    margin: 0 var(--spacing-level-6);
  }

  @media (--under-s-breakpoint) {
    margin: 0 var(--spacing-level-4);
    height: 214px;
  }

  @media (width <= 480px) {
    margin: 0;
  }

  .tagWrapper {
    padding-top: var(--spacing-level-4);
  }

  .headerWrapper {
    position: absolute;
    bottom: 0;
    background-color: transparent !important;
  }

  h1 {
    box-decoration-break: clone;
    line-height: 4.2rem;
    font-size: 3.6rem;
    letter-spacing: -0.7px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 var(--spacing-level-3) 0 var(--spacing-level-6);
    display: inline;
    background-color: var(--color-white);
    color: var(--color-full-black);
    
    span {
      background-color: var(--color-white);
      display: block;
      height: 2rem;
      width: 2.1rem;

      @media (--under-s-breakpoint) {
        margin-top: -1px;
      }
    }

    span:nth-of-type(2)      {
      display: inline-block;
      width: 2.3rem;
      height: 3.6rem;
      margin-top: 0;
    }
  }

  .decoration {
    width: 130px;
    height: 16px;
    background-color: var(--color-white);
    display: block;
  }

  .padlockIcon {
    position: absolute;
    right: var(--spacing-level-6);
    bottom: var(--spacing-level-6);
    filter: invert(1);
  }
}

.wrapper:not(:has(.lockedBenefit)) {
  @media (--under-s-breakpoint) {
    background-position: 70% 0;
  }
}

.lockedBenefit {
  height: 214px;
  background: var(--color-loyalty-off-white) url("../../../../components/navigation/components/nav-item/img/mid-grey-white-noise.svg") center center repeat-x;

  h1 {
    background-color: var(--color-full-black);
    color: var(--color-white)
  }

  .decoration {
    background-color: var(--color-full-black);
  }

  .padlockIcon {
    filter: invert(0);
  }
}

.tier2 {
  background: #9797ff url("../../../../components/navigation/components/nav-item/img/mid-purple-white-noise.svg") center center repeat-x;
}

.tier3 {
  background: var(--asos-world-violet) url("../../../../components/navigation/components/nav-item/img/purple-white-noise.svg") center center repeat-x;

  .padlockIcon {
    filter: invert(1);
  }
}