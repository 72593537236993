@import url("../../styles/_variables.css");

.ConsolidatedReturnsMessage {
  background-color: #ffebcc;
  text-align: left;
  padding-right: var(--spacing-level-11);
  margin: 0 -16px var(--spacing-level-6);
}

.ConsolidatedReturnsMessageLink {
  padding-top: var(--spacing-level-1);
}

.ConsolidatedReturnsMessageLink a {
  font-weight: bold;
  text-decoration: none;
}

.ConsolidatedReturnsMessageLink a:hover {
  text-decoration: underline;
}

@media (--s-breakpoint) {
  .ConsolidatedReturnsMessage {
    margin: 0 calc(0px - var(--spacing-level-6)) var(--spacing-level-6);
  }
}

@media (--l-breakpoint) {
  .ConsolidatedReturnsMessage {
    margin: 0 calc(0px - var(--spacing-level-8)) var(--spacing-level-8);
  }
}
