@import url("../../../styles/_variables.css");

.titleWrapper {
  padding-bottom: var(--spacing-level-1);
}

.buttonWrapper {
  padding-top: var(--spacing-level-3);
}

@media (--s-breakpoint) {
  .titleWrapper {
    padding-bottom: var(--spacing-level-3);
  }
}
