@import url("../../../../styles/_variables.css");

:root {
  --optical-alignment-nudge: 2px;
  --icon-size: 20px;
  --cta-max-width: 180px;
}

.storeWrapper {
  position: relative;
}

.storeNameWrapper {
  padding-bottom: var(--spacing-level-1);
}

.storeAddress {
  padding-bottom: var(--spacing-level-3);
  font-size: var(--h4-size);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-spacing);
}

.storeName {
  font-weight: bold;
  font-size: var(--h4-size);
  line-height: var(--h4-line-height);
  letter-spacing: var(--h4-spacing);
}

.ctaWrapper {
  max-width: var(--cta-max-width);
  padding-bottom: var(--spacing-level-4);
}

.providerIcon {
  margin-top: var(--optical-alignment-nudge);
  width: var(--icon-size);
  height: var(--icon-size);
  float: left;
}

.storeDetailsWrapper {
  margin-left: var(--icon-size);
  padding-left: var(--spacing-level-3);
}

.printerIcons {
  padding-top: var(--spacing-level-3);
  padding-bottom: var(--spacing-level-6);
}

.icon {
  float: left;
}

.name {
  line-height: 20px;
  padding-left: var(--spacing-level-2);
  display: inline-block;
}

@media (--m-breakpoint) {
  .storeAddress {
    padding-bottom: var(--spacing-level-4);
  }

  .storeDetails {
    max-width: auto;
  }

  .ctaWrapper {
    position: relative;
    max-width: var(--cta-max-width);
  }
}

@media (--under-m-breakpoint) and (--xs-breakpoint),
  (--orders-list-l-breakpoint) {
  .storeDetails {
    max-width: 65%;
    padding-right: var(--spacing-level-3);
  }

  .ctaWrapper {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 35%;
  }
}

@media (--orders-list-l-breakpoint) {
  .storeAddress {
    padding-bottom: 0;
  }
}

@media (--l-breakpoint) {
  .storeDetailsWrapper {
    margin-left: var(--icon-size);
    padding-left: var(--spacing-level-4);
  }
}
