@import url("../../styles/_variables.css");

.price {
  line-height: 1.43;
  white-space: nowrap;
  font-weight: 700;
  letter-spacing: 0.2rem;
}

.reduced {
  color: var(--color-lipstick-red);
}

.was {
  font-weight: normal;
  letter-spacing: var(--body-spacing);
  text-decoration: line-through;
  color: var(--asos-grey-70);
}
