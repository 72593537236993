@import url("../../../styles/_variables.css");

.premierActivationErrorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20% 10% 0;
}

.premierActivationErrorTitle {
  font-size: var(--barnsley4-font-size);
  line-height: var(--barnsley4-line-height);
  letter-spacing: var(--barnsley4-letter-spacing);
  font-weight: var(--barnsley4-font-weight);
  margin-bottom: 20px;
}

.premierActivationErrorText {
  text-align: center;
}

.sadFaceIcon {
  font-size: 100px;
  color: var(--color-bright-yellow);
}

.premierActivationErrorCtas {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}

.continueCheckoutButton {
  margin-top: 10px;
}
