@import url("../../../styles/_variables.css");

.cardWrapper {
  margin-top: var(--spacing-level-3);

  @media (--under-m-breakpoint) {
    margin-top: 0;
    height: calc(100vh - 50px);
    top: 50px;
    left: 0;
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    scroll-snap-type: y proximity;

    &:has(.disableScrollSnap) {
      scroll-snap-type: none;
    }
  }

}

.isWebView {
  @media (--under-m-breakpoint) {
    height: 100%;
    top: 0;
    position: fixed;
  }
}

.hideCardWhenScrolling {
  height: 52px;
  width: 100%;
  background-color: rgb(238, 238, 238);
}

.mobileFooter {
  display: none;

  @media (--under-m-breakpoint) {
    display: unset;
  }
}

.benefits {
  margin: var(--spacing-level-4) 0 var(--spacing-level-6) 0;
  display: grid;
  column-gap: var(--spacing-level-4);
  row-gap: var(--spacing-level-4);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;

  @media (--under-s-breakpoint) {
    margin: var(--spacing-level-3) 0 var(--spacing-level-5) 0;
    column-gap: var(--spacing-level-3);
    row-gap: var(--spacing-level-3);
  }
}

.tierName {
  display: inline-flex;
  align-items: center;

}

.tierHeading {
  display: flex;
  align-items: center;
  line-height: var(--small-interactive-line-height);
  font-size: var(--small-interactive-size);
  justify-content: space-between;
}

.benefitSpend {
  font-size: var(--small-size);
  line-height: var(--h1-size);
}

.loyaltyDrawerHandleContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 24px;
  position: sticky;
  background-color: var(--asos-white);
  align-items: center;
  top: -2px;
  left: -5%;
  z-index: 13;

  .loyaltyDrawerHandle {
    height: 4px;
    width: 40px;
    background-color: var(--asos-grey-40);
    text-align: center;
    border-radius: 50px;
  }

  @media (--m-breakpoint) {
    display: none;
  }
}

.loyaltyDrawer {
  position: relative;
  z-index: 12;
  width: 100%;
  top: 0;
  border-top: 2px solid var(--asos-grey-40);
  background-color: var(--asos-white);
  scroll-snap-align: start;

  @media (--m-breakpoint) {
    position: relative;
    border: none;
    border-radius: unset;
    top: 0;
  }

  @media (--under-m-breakpoint) {
    top: 302px;
    padding: 0 !important;
  }
}

.nativeTopPosition {
  @media (--under-m-breakpoint) {
    top: 308px;
  }
}

.nativeTier4TopPosition {
  @media (--under-m-breakpoint) {
    top: 290px;
  }
}

.fixed {
  @media (--under-m-breakpoint) {
    position: fixed;
    top: 94px;
    overflow: visible;
    width: 100%;
  }
}

.isWebView .fixed {
  @media (--under-m-breakpoint) {
    top: 0;
  }
}

@media (--under-m-breakpoint) {
  .headerWrapper {
    z-index: 10;
    position: sticky;
    top: 48px;
    background-color: var(--asos-white);
  }

  .headerWrapperNative {
    top: -4px;
  }
}

.tierCardContainer {
  background-color: var(--color-gallery-gray);
  display: flex;
  align-items: center;
  padding-top: var(--spacing-level-4) !important;

  @media (--under-l-breakpoint) {
    flex-direction: column;
    padding-bottom: var(--spacing-level-3) !important;
    padding-top: var(--spacing-level-6) !important;
  }
}

.welcomeMessage {
  span {
    font-size: var(--h2-size);
    line-height: var(--h2-line-height);
    font-weight: 600;
    padding-left: var(--spacing-level-8);
    color: var(--color-dark-gray);
  }

  @media (--under-m-breakpoint) {
    display: none;
  }
}

.progressDetails {
  padding-left: var(--spacing-level-6);

  span {
    font-weight: 600;
    font-size: var(--small-size);
    line-height: var(--small-line-height);
  }

  p {
    padding-bottom: var(--spacing-level-1);

    @media (--under-m-breakpoint) {
      padding-bottom: 0;
    }

    span {
      color: var(--color-dark-gray);
      font-weight: normal;

      @media (--under-m-breakpoint) {
        font-size: var(--body-size);
        line-height: var(--small-line-height);
      }

    }
  }

  div {
    padding-bottom: var(--spacing-level-2);
  }

  div:last-of-type {
    padding-bottom: 0;
  }

  @media (--under-l-breakpoint) {
    padding-left: 0;
    padding-top: var(--spacing-level-6);
    width: 100%;

    div {
      display: flex;
      justify-content: space-between;
    }
  }
}

.benefitsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--spacing-level-6);
  border-bottom: 1px solid var(--color-silver);

  @media (--under-m-breakpoint) {
    padding: var(--spacing-level-4) 0 var(--spacing-level-6) 0;
    margin: 0 var(--spacing-level-4) var(--spacing-level-4);
  }

  h2>span {
    font-size: var(--h2-size);
    line-height: var(--h2-size);
  }
}

.benefitWrapper {
  padding: var(--spacing-level-6) 0;

  @media (--under-m-breakpoint) {
    padding: var(--spacing-level-6) var(--spacing-level-4);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.firstView {
  animation: fadeIn 1.2s;

  .welcomeNotification {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 620px;
    z-index: 15;

    @media (--under-s-breakpoint) {
      position: fixed;
      bottom: 0;
      top: auto;
      min-width: 100%;
    }
  }
}

.learnMore {
  padding-top: var(--spacing-level-6);
  border-top: 1px solid var(--color-silver);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--under-m-breakpoint) {
    padding: var(--spacing-level-6) 0;
    margin: 0 var(--spacing-level-4) var(--spacing-level-4);
  }
}

/* Dark theme */
.cardWrapper.dark {
  .loyaltyDrawerHandleContainer {
    background-color: var(--asos-grey-00);
  }

  .loyaltyDrawer {
    border-top: 2px solid var(--asos-grey-00);
  }

  .tierCardContainer {
    background-color: var(--asos-full-black);
    color: var(--asos-white);
  }

  .progressDetails {
    span {
      color: var(--asos-grey-40);
    }

    p {
      span {
        color: var(--asos-grey-40);
      }
    }
  }

  .benefitsHeader {
    border-bottom: 1px solid var(--asos-grey-00);
  }
}