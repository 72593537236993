@import url("../../styles/_variables.css");

:root {
  --heading-height-small: 44px;
}

.loyaltyPageHeader {
  display: none;

  @media (--under-m-breakpoint ) {
    display: block;
    position: fixed;
    top: 44px;
    overflow: visible;
    width: 100%;
    z-index: 13;
  }  
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.rightheadingButton {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pageHeading {
  background-color: var(--color-white);
  padding: 13px var(--spacing-level-4);
  text-align: center;
  position: relative;
}

.detached {
  margin-bottom: var(--spacing-level-3);
}

.pageHeading.withInfo {
  padding-bottom: 0;
}

.title {
  display: inline-block;
  vertical-align: middle;
}

.showOnSmall {
  display: block;
}

.iconGroup {
  display: block;
  font-size: 0;
  position: absolute;
  left: var(--spacing-level-4);
  top: 16px;
}

.largePageIcon {
  display: none;
}

.icon {
  height: 1.5rem;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.hideOnSmall {
  display: none;
}

.description {
  max-width: var(--text-max-width);
  text-align: left;
  padding-top: var(--spacing-level-3);
}

.description.info {
  display: block;
  background-repeat: no-repeat;
  background-position: 0 4px;
  background-image: url("../notification/icon-information.svg");
  background-color: var(--color-white);
  padding: 0;
  margin-top: var(--spacing-level-7);
  padding-left: var(--spacing-level-8);
  position: relative;
  text-align: left;
}

.headingButton {
  background-color: var(--color-gallery-gray);
  padding: var(--spacing-level-4);
  text-align: center;
  display: flex;
  justify-content: center;
}

.chromeless.iconGroup {
  left: auto;
  right: 16px;
  z-index: 1;
  max-height: 22px;
  max-width: 22px;
}

@media (--s-breakpoint) {
  .headingButton {
    padding: var(--spacing-level-5);
    justify-content: center;
  }

  .pageHeading {
    padding: var(--spacing-level-4) var(--spacing-level-6);
  }

  .iconGroup {
    left: var(--spacing-level-6);
    top: var(--spacing-level-5);
  }
}

@media (--m-breakpoint) {
  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .rightheadingButton {
    margin-top: unset;
    flex: 1 0 45%;
  }

  .description:not(.info) {
    display: block;
    padding-top: 0;
  }

  .description.info {
    margin-top: 0;
  }

  .pageHeading {
    text-align: left;
    padding: var(--spacing-level-6) var(--spacing-level-6) 0;
  }

  .pageHeading.detached {
    padding-bottom: var(--spacing-level-6);
  }

  .bottomPadding {
    padding-bottom: var(--spacing-level-6);
  }

  .pageHeading.withButton {
    padding-bottom: 0;
  }

  .pageHeading.withTabs {
    /* Temporary spacing until tabs are created */
    padding-bottom: var(--spacing-level-6);
  }

  .iconGroup {
    position: relative;
    left: 0;
    top: 0;
  }

  .icon {
    height: 2.8rem;
    display: none;
  }

  .largePageIcon {
    display: block;
    font-size: 0;
    margin-bottom: var(--spacing-level-3);
  }

  .headingButton {
    background-color: var(--color-white);
    padding: 6px var(--spacing-level-6) var(--spacing-level-6);
    text-align: left;
    justify-content: left;
  }

  .showOnSmall {
    display: none;
  }

  .chromeless {
    display: block;

    &.iconGroup {
      position: absolute;
      right: 24px;
      top: 20px;
    }

    & .showOnSmall {
      display: block;
    }

    & .icon {
      display: block;
    }
  }
}

@media (--l-breakpoint) {
  .icon {
    height: 3rem;
  }

  .pageHeading {
    padding: var(--spacing-level-8) var(--spacing-level-8) 0;
  }

  .pageHeading.detached {
    padding-bottom: var(--spacing-level-8);
  }

  .bottomPadding {
    padding-bottom: var(--spacing-level-8);
  }

  .pageHeading.withButton {
    padding-bottom: 0;
  }

  .headingButton {
    padding-top: var(--spacing-level-3);
    padding-bottom: var(--spacing-level-8);
    padding-left: var(--spacing-level-8);
  }

  .chromeless.iconGroup {
    right: 32px;
    top: 28px;
  }
}
