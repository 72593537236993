@import url("../../../../../styles/_variables.css");

:root {
  --gift-card-width-medium: 200px;
  --gift-card-height-medium: 118px;
  --gift-card-area-width-medium: calc(var(--gift-card-width-medium) + var(--spacing-level-8));
  --gift-card-height-large: 193px;
  --gift-card-width-large: 288px;
  --gift-card-area-width-large: calc(var(--gift-card-width-large));
}

.lineBreak {
  padding-top: var(--spacing-level-4);
  padding-bottom: var(--spacing-level-5);
}

.giftCardWrapper {
  padding-bottom: var(--spacing-level-6);
  width: 240px;
  margin: 0 auto;
}

.details {
  line-height: var(--body-line-height);
}

.status {
  padding-top: var(--spacing-level-1);
}

.initialBalanceDetail {
  padding-bottom: var(--spacing-level-3);
}

.returnslink {
  padding-top: var(--spacing-level-6);
  background-color: #ff0;
}

@media (--m-breakpoint) {
  .giftCardWrapper {
    width: 200px;
    float: left;
    margin: 0;
    margin-right: var(--spacing-level-8);
  }

  .details {
    padding-left: var(--gift-card-area-width-medium);
  }

  .lineBreak {
    padding-top: var(--spacing-level-4);
    padding-bottom: var(--spacing-level-5);
  }
}

@media (--l-breakpoint) {
  .cardSectionContent {
    position: relative;
  }

  .giftCardWrapper {
    width: var(--gift-card-width-large);
    position: absolute;
    top: var(--spacing-level-8);
  }

  .details,
  .headingSection {
    padding-left: var(--gift-card-area-width-large);
    margin-left: var(--spacing-level-10);
  }

  .lineBreak {
    overflow: hidden;
  }
}
