@import url("../../../styles/_variables.css");

.voucher {
  margin: auto;
  text-align: center;
  width: 160px;
}

@media (--s-breakpoint) {
  .voucher {
    margin: initial;
    padding-left: var(--spacing-level-1);
    padding-top: var(--spacing-level-6);
    text-align: left;
    width: 186px;
  }
}

@media (--l-breakpoint) {
  .voucher {
    padding-top: var(--spacing-level-8);
    width: 217px;
  }
}
