@import url("../../styles/_variables.css");

.errorAppear {
  opacity: 0;
}

.errorAppear.errorAppearActive {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.errorWrapperButton button {
  max-width: 330px !important;
}

.retryErrorWraper {
  padding-left: var(--average-spacing-mobile);
  padding-right: var(--average-spacing-mobile);
}

@media (--s-breakpoint) {
  .retryErrorWraper {
    padding-left: var(--average-spacing-tablet);
    padding-right: var(--average-spacing-tablet);
  }
}

@media (--l-breakpoint) {
  .retryErrorWraper {
    padding-left: var(--average-spacing-desktop);
    padding-right: var(--average-spacing-desktop);
  }
}
