@import url("../../../../styles/_variables.css");

.warningMessageWrapper {
  display: flex;
  padding: var(--spacing-level-5);
  background-color: var(--color-light-gold);
  margin-bottom: var(--spacing-level-3);
}

.warningHeading {
  text-transform: uppercase;
  margin-bottom: var(--spacing-base);
}

.warningMessage {
  margin-left: var(--spacing-level-5);
}

.warningMessageBody a {
  text-decoration: underline;
}

.warningMessageBody b {
  font-weight: bold;
}
