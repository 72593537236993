@import url("../../../styles/_variables.css");

.content {
  text-align: center;
}

.iconContainer {
  padding: var(--spacing-level-6);
  padding-top: var(--spacing-level-4);
}

.iconContainer > img {
  display: inline-block;
  width: 40px;
}

.paragraphContainer {
  padding-top: var(--spacing-level-4);
  padding-bottom: var(--spacing-level-6);
  max-width: var(--text-max-width);
  margin: 0 auto;
}

@media (--m-breakpoint) {
  .iconContainer {
    padding-top: var(--spacing-level-6);
  }
}
