.notificationAppear {
  opacity: 0.01;
  transform: translateY(100%);

  &.notificationAppearActive {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms ease-in 500ms, transform 500ms ease-in 500ms;
  }
}

.notificationLeave {
  opacity: 1;
  transform: translateY(0);

  &.notificationLeaveActive {
    opacity: 0.01;
    transform: translateY(100%);
    transition: opacity 500ms ease-in, transform 500ms ease-in;
  }
}
