@import url("../../../../../styles/_variables.css");

.headerClass {
  background-color: var(--color-gallery-gray);
  padding-bottom: 32px;
}

.container {
  text-align: left;
  background-color: var(--color-gallery-gray);
  height: 100vh;
}

.content {
  display: flex;
  background-color: var(--color-white);
  padding: var(--spacing-level-4);
}

.icon {
  height: var(--icon-size);
  margin-right: var(--spacing-level-3);
}

.openingTimes {
  margin-top: var(--spacing-level-4);
}

.day {
  display: inline-block;
  width: 135px;
}

.modalClass {
  width: 375px;
  height: 100vh;
} 

.overlayClass {
  display: flex;
  justify-content: flex-end;
  padding: 0;
} 

@media (--under-m-breakpoint) {
  .modalClass {
    width: 100vw;
  }
}
