@import url("../../../../styles/_variables.css");

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.container {
  margin-top: var(--spacing-level-6);
  background-color: var(--color-white);

  h1 {
    font-size: var(--h3-size);
    line-height: var(--h3-line-height);
    text-transform: uppercase;
    font-weight: 600;
    padding: var(--spacing-level-4);
  }

  .tabs {
    display: flex;
    padding: 0 var(--spacing-level-4);

    .tab {
      width: 50%;
      text-align: center;
      cursor: pointer;
      background: none;
      border: none;
      text-transform: uppercase;
      height: 48px;
      font-weight: 600;
      font-size: var(--h5-size);
      line-height: var(--h6-line-height);
      border-bottom: 2px solid var(--asos-grey-40);
      color: var(--asos-grey-90);
    }

    .active {
      border-bottom: 2px solid var(--color-black);
      color: var(--color-black);
    }
  }

  .eventWrapper {
    background-color: var(--color-gallery-gray);
    padding: var(--spacing-level-2);
    animation: fadeIn 0.6s ease;
  }

  .eventImage {
    width: 100%;
    height: 374px;

    @media (--under-m-breakpoint) {
      height: 286px;
    }
  }

  .eventDetails {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    padding: var(--spacing-level-4);

    h3 {
      font-weight: 600;
    }

    .eventStart {
      padding-top: var(--spacing-level-2);
    }

    a {
      min-width: 100%;
      margin-top: var(--spacing-level-2);
    }

    .eventIcon {
      margin-right: var(--spacing-level-1);
    }

  }

  .carousel {
    background-color: var(--color-gallery-gray);
    display: block;

    nav {
      ul {
        margin-top: 0;
        padding-bottom: var(--spacing-level-2);
      }

      button {
        border: 1px solid var(--color-black);
      }
    }

    div:first-of-type {
      [class*="_next_"], [class*="_previous_"] {
        position: absolute;

        @media (--under-m-breakpoint) {
          border: 0;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          width: 1px;
        }
      }

      div {
        min-width: 100%;
      }

      ul {
        width: 100%;
      }

      li {
        width: 50% !important;
        margin-right: 0;
      }

      @media (--under-m-breakpoint) {
        li {
          width: 80% !important;
          scroll-snap-align: start;
        }

        ul {
          scroll-snap-type: x proximity;
        }
      }
    }
  }

  /* stylelint-disable no-descending-specificity */
  .lockedView {
    div:first-of-type {
      li {
        width: 100% !important;
      }

      @media (--under-m-breakpoint) {
        li {
          width: 80% !important;
          scroll-snap-align: start;
        }
      }
    }
  }
}

/* Dark theme */
.container.dark {
  .eventDetails {
    background-color: var(--color-dark-layer-1) !important;
  }

  nav {
    background: var(--asos-full-black);

    button {
      filter: invert(1);
    }
  }

  .eventWrapper {
    background-color: var(--asos-full-black);
  }

  .tabs {
    .tab {
      border-bottom: 2px solid var(--asos-grey-70);
      color: var(--color-silver);
    }

    .active {
      border-bottom: 2px solid var(--asos-white);
      color: var(--asos-white);
    }

    .inactive {
      border-bottom: 2px solid var(--color-black);
    }
  }
}