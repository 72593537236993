@import url("../../../../../styles/_variables.css");

.label {
  font-size: var(--h6-size);
  font-weight: bold;
  letter-spacing: var(--h6-spacing);
  line-height: var(--h6-line-height);
  padding-right: var(--spacing-level-2);
  text-transform: uppercase;
  margin-top: calc(0px - var(--spacing-level-1));
  overflow: hidden;
}

.infoField {
  margin-top: var(--spacing-level-4);
  font-size: var(--h4-size);
  line-height: var(--body-line-height);
  display: flex;
  white-space: pre-wrap;
  flex-direction: row;
}

.infoField img {
  margin: 5px 10px 0 5px;
}

.returnToBrandInfo a {
  color: var(--color-cerulean-blue);
}
