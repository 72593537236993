@import url("../../../styles/_variables.css");

.cardImage {
  display: block;
  padding-top: 0;
  margin: 0 auto;
  width: 168px;
}

@media (--s-breakpoint) {
  .cardImage {
    margin: initial;
    width: 192px;
  }
}

@media (--m-breakpoint) {
  .cardImage {
    padding-top: var(--spacing-level-6);
  }
}

@media (--l-breakpoint) {
  .cardImage {
    padding-top: var(--spacing-level-8);
    width: 224px;
  }
}
