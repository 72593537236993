@import url("../../styles/_variables.css");

.ellipsis {
  display: none;
}

.greeting {
  background-color: var(--color-white);
  font-size: 1.8rem;
  padding: 18px var(--spacing-level-4) var(--spacing-level-4);
}

.greetingText {
  font-size: 1.6rem;
}

.names {
  font-weight: bold;
  letter-spacing: 0.2rem;
  line-height: var(--body-line-height);
  min-height: var(--body-line-height);
  padding-top: 6px;
  word-wrap: break-word;
}

.restOfName {
  display: inline;
}

.textContainer {
  margin: auto;
  max-width: var(--text-max-width);
}

.headerImage {
  background-size: cover;
  padding-top: 30%;
}

.lowerCard {
  background-color: var(--color-white);
}

@keyframes slideDown {
  0% {
    margin-top: calc(0px - var(--spacing-level-6));
  }

  100% {
    margin-top: 0;
  }
}

@media (--under-m-breakpoint) {
  .headerImage {
    background-position: center top;
    background-image: url("./nav-white-noise.svg");
  }

  .lowerCard {
    border-top: 4px solid transparent;
  }

  .lowerCard.loaded {
    animation-duration: 0.35s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-name: slideDown;
    animation-play-state: running;
  }

  .wrapper {
    text-align: center;
  }
}

@media (--xs-breakpoint) {
  .headerImage {
    padding-top: 0;
    height: var(--nav-header-image-xs);
  }
}

@media (--s-breakpoint) {
  .headerImage {
    height: var(--nav-header-image-s);
  }
}

@media (--m-breakpoint) {
  .ellipsis {
    display: inline-block;
  }

  .headerImage {
    display: none;
  }

  .greeting {
    display: inline-block;
    padding: 0 0 0 var(--spacing-level-3);
    vertical-align: middle;
    width: 60%;
    word-wrap: break-word;
  }

  .restOfName {
    display: none;
  }

  .wrapper {
    margin: 0;
    padding: var(--spacing-level-7) var(--spacing-level-4) var(--spacing-level-7) 0;
    text-align: left;
  }

  .lowerCard.loaded {
    animation: none;
  }
}

@media (--l-breakpoint) {
  .greeting {
    width: 70%;
  }

  .wrapper {
    padding: var(--spacing-level-10) var(--spacing-level-4) var(--spacing-level-10) 0;
  }
}
