/* stylelint-disable at-rule-empty-line-before */
:root {
  --small-min-width: 320px;
  --field-max-width: 330px;
  --large-max-width: 960px;

  /* Grid */
  --small-half-gutter: 5px;
  --medium-half-gutter: 8px;
  --large-half-gutter: 10px;
  --full-width: 100%;
  --total-columns: 12;

  /* Images */
  --product-image-ratio: 1.27;
  --voucher-image-ratio: 0.64;

  /* Paddings */
  --spacing-base: 4px;
  --spacing-level-1: calc(1 * var(--spacing-base)); /* 4px */
  --spacing-level-2: calc(2 * var(--spacing-base)); /* 8px */
  --spacing-level-3: calc(3 * var(--spacing-base)); /* 12px */
  --spacing-level-4: calc(4 * var(--spacing-base)); /* 16px */
  --spacing-level-5: calc(5 * var(--spacing-base)); /* 20px */
  --spacing-level-6: calc(6 * var(--spacing-base)); /* 24px */
  --spacing-level-7: calc(7 * var(--spacing-base)); /* 28px */
  --spacing-level-8: calc(8 * var(--spacing-base)); /* 32px */
  --spacing-level-9: calc(9 * var(--spacing-base)); /* 36px */
  --spacing-level-10: calc(10 * var(--spacing-base)); /* 40px */
  --spacing-level-11: calc(11 * var(--spacing-base)); /* 44px */
  --spacing-level-12: calc(12 * var(--spacing-base)); /* 48px */
  --spacing-level-16: calc(16 * var(--spacing-base)); /* 64px */

  /* Fonts */
  --font-stack: futura-pt, Futura, Tahoma, Geneva, Verdana, Arial, sans-serif;
  --text-max-width: 550px;

  /* h6 */
  --h6-size: 1rem;
  --h6-line-height: 1.4rem;
  --h6-spacing: 0.12rem;

  /* h5 */
  --h5-size: 1.2rem;
  --h5-line-height: 1.8rem;
  --h5-spacing: 0.17rem;

  /* h4 */
  --h4-size: 1.4rem;
  --h4-line-height: 2.4rem;
  --h4-spacing: 0.2rem;

  /* h3 */
  --h3-size: 1.6rem;
  --h3-line-height: 2.4rem;
  --h3-spacing: 0.2rem;

  /* h2 */
  --h2-size: 1.8rem;
  --h2-line-height: 2.2rem;
  --h2-spacing: 0.25rem;

  /* h1 */
  --h1-size: 2rem;
  --h1-line-height: 2.2rem;
  --h1-spacing: 0.28rem;

  /* editorial */
  --editorial-size: 2.6rem;
  --editorial-line-height: 4rem;
  --editorial-spacing: 0.3rem;

  /* body */
  --body-size: 1.6rem;
  --body-line-height: 2.4rem;
  --body-spacing: 0.05rem;

  /* small */
  --small-size: 1.4rem;
  --small-line-height: 2rem;
  --small-spacing: 0.05rem;

  /* small interactive - e.g. small buttons & tags */
  --small-interactive-size: 1.2rem;
  --small-interactive-line-height: 1.7rem;
  --small-interactive-spacing: 0.15rem;

  /* Main Colour Palette */
  --color-black: var(--asos-black);
  --color-full-black: var(--asos-full-black);
  --color-black-rgb: var(--asos-black-rgb);
  --color-dark-gray: var(--asos-grey-90);
  --color-med-gray: var(--asos-grey-70);
  --color-silver: var(--asos-grey-40);
  --color-gallery-gray: var(--asos-grey-20);
  --color-off-white: var(--asos-grey-10);
  --color-white: var(--asos-white);
  --color-white-rgb: var(--asos-white-rgb);

  /* Main Colour Palette - Dark */
  --color-dark-layer-1: #1F1E1E; /* todo put in component library */
  --color-dark-layer-2: #282828; /* todo put in component library */

  /* Notification Color Palette */
  --color-cerulean-blue: #0770cf;
  --color-accessible-contrast-blue: #0000c1;
  --color-light-cerulean-blue: #cde2f5;
  --color-bright-red: #ff3b30;
  --color-lipstick-red: #d01345;
  --color-light-lipstick-red: #fae7ec;
  --color-shamrock-green: #018849;
  --color-light-shamrock-green: #cceede;
  --color-dark-shamrock-green: #006637;
  --color-gold: #ffd95a;
  --color-light-gold: #ffebcc;
  --color-bright-yellow: #f44336;
  --color-loyalty-off-white: #F5F5F2;
  --color-violet: var(--asos-world-violet);
  --color-violet-10: var(--asos-world-v-light-violet);
  --color-dark-violet: var(--asos-world-dark-violet);
  --color-lilac: #E6DCF4;
  --color-purple: #222065;
  --color-dark-purple: #1B1B3A;

  /* animation */
  --full-page-loading-animation: fullPageLoadingAnimation 2s ease-in-out
    infinite;

  /* navigation component */
  --nav-icon-margin: 18px;
  --nav-icon-space: 68px;
  --nav-circle-size-desktop: 88px;
  --nav-circle-size-mobile: 100px;
  --nav-header-image-xs: 150px;
  --nav-header-image-s: 180px;
}

@value xsBreakpoint: (min-width: 480px);
@value xsBreakpointMax: (max-width: 639px);
@value sBreakpoint: (min-width: 640px);
@value sBreakpointMax: (max-width: 719px);
@value mBreakpoint: (min-width: 720px);
@value mBreakpointMax: (max-width: 839px);
@value ordersListLBreakpoint: (min-width: 840px);
@value ordersListLBreakpointMax: (max-width: 959px);
@value lBreakpoint: (min-width: 960px);

@custom-media --xs-breakpoint xsBreakpoint;
@custom-media --s-breakpoint sBreakpoint;
@custom-media --m-breakpoint mBreakpoint;
@custom-media --l-breakpoint lBreakpoint;
@custom-media --orders-list-l-breakpoint ordersListLBreakpoint;
@custom-media --under-s-breakpoint xsBreakpointMax;
@custom-media --under-m-breakpoint sBreakpointMax;
@custom-media --under-orders-list-l-breakpoint mBreakpointMax;
@custom-media --under-l-breakpoint ordersListLBreakpointMax;
@custom-media --chrome-only (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm);

@keyframes fullPageLoadingAnimation {
  0% {
    background-color: var(--color-gallery-gray);
  }

  50% {
    background-color: var(--color-silver);
  }

  100% {
    background-color: var(--color-gallery-gray);
  }
}
