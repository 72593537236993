@import url("../../../styles/_variables.css");

:root {
  --main-content-grid-size: 8;
  --column-width: calc(var(--large-max-width) / var(--total-columns));
  --chromeless-max-width: calc(var(--column-width) * var(--main-content-grid-size));
}

.contentWrapper {
  min-width: var(--small-min-width);
  max-width: calc(var(--large-max-width) - (2 * var(--large-half-gutter)));
  min-height: 1px;
  margin: auto 0 0;
}

.contentWrapper.chromeless {
  margin: auto auto 0;
  max-width: calc(var(--chromeless-max-width) - (2 * var(--large-half-gutter)));
}

.paddingSmall {
  padding-left: var(--small-half-gutter);
  padding-right: var(--small-half-gutter);
}

.paddingMedium {
  padding-left: var(--medium-half-gutter);
  padding-right: var(--medium-half-gutter);
}

@media (--m-breakpoint) {
  .contentWrapper {
    margin: auto var(--large-half-gutter) 0;
  }
}

@media (--l-breakpoint) {
  .contentWrapper {
    margin: auto auto 0;
    width: 100%;
  }
}
