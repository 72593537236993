@import url("../../../styles/_variables.css");

/* This component must exist within a relative-positioned parent */

@value defaultImageMaxSize: 140px;
@value smallImageMaxSize: 100px;
@value mediumImageMaxSize: 150px;
@value largeListImageMaxSize: 100px;

.overlay {
  background: rgb(33, 33, 33, 50%);
  inset: 0;
  color: var(--color-white);
  font-size: var(--editorial-size);
  font-weight: 700;
  letter-spacing: var(--editorial-spacing);
  line-height: 125px;
  position: absolute;
  text-align: center;
  z-index: 1;
}

.extra {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
