@import url("../../../../../styles/_variables.css");

.returnSignpost {
  padding: var(--spacing-level-6) var(--spacing-level-6) 0;
  text-align: left;
}

.link a {
  text-decoration: underline;
}
