@import url("../../../styles/_variables.css");

.wrapper {
  background-color: var(--asos-world-violet);
  color: var(--color-white);
  text-transform: uppercase;
  width: fit-content;
  font-weight: 600;
  font-size: var(--h6-size);
  line-height: var(--h6-line-height);
  letter-spacing: 0.12rem;
  height: 20px;
  align-items: center;
  display: flex;
  padding: 0 var(--spacing-level-2)
}

.tileTag {
  position: absolute;
  top: -8px;
  left: -16px;
}