@import url("../../../styles/_variables.css");

.addMargin {
  @media (--xs-breakpoint) {
    margin: 0 var(--average-spacing-mobile);
  }

  @media (--s-breakpoint) {
    margin: 0 var(--average-spacing-tablet);
  }

  @media (--m-breakpoint) {
    margin: 0;
  }
}
