@import url("../../styles/_variables.css");

.scrollTop {
  border: none;
  background-color: var(--color-gallery-gray);
  opacity: 0.95;
  letter-spacing: 0.2rem;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
}

.scrollTop:focus,
.show {
  width: 100%;
  height: 40px;
  margin: 0;
  overflow: auto;
  clip: auto;
  visibility: visible;
}

.text {
  padding-left: 11px;
}

.icon {
  position: relative;
  top: 2px;
}
