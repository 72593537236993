@import url("../../../styles/_variables.css");

.benefitDetailsHero {
  margin-top: var(--spacing-level-2);

  @media (--under-m-breakpoint) {
    margin-top: 0;
  }
}

.birthdayTileNoDOB {
  height: 214px;
}

.detailsWrapper {
  padding: 0 var(--spacing-level-6) var(--spacing-level-6);
  font-size: var(--body-size);
  line-height: var(--body-line-height);

  h2 {
    padding: var(--spacing-level-6) 0 var(--spacing-level-2) 0;
    font-size: var(--h2-size);
    line-height: var(--h2-line-height);
    font-weight: 600;;
  }

  .infoBanner {
    padding-top: var(--spacing-level-6);
    
    @media (--under-m-breakpoint) {
      margin: 0 var(--spacing-level-6);
    }
  }

  .howToUse {
    background: var(--color-gallery-gray);
    padding: var(--spacing-level-4);
    margin-top: var(--spacing-level-6);

    h3 {
      font-size: var(--h3-size);
      line-height: var(--h3-line-height);
      font-weight: 600;
      padding-bottom: var(--spacing-level-1);
    }

    ul {
      padding-left: var(--spacing-level-2);
    }

    span {
      font-weight: 600;
    }

    p {
      padding-top: var(--spacing-level-2);
    }
  }
  
  .ctaButton {
    margin-top: var(--spacing-level-6);
    width: 342px;
    max-width: 100%;

    @media (--under-m-breakpoint) {
      width: calc(100% - 48px);
    }
  }
}

@media (--under-m-breakpoint) {
  .detailsWrapper {
    padding: 0 0 var(--spacing-level-6) 0;
  }

  div:has(.lockedBenefit) {
    height: 100%;
  }

  .benefitDesc {
    padding: 0 var(--spacing-level-6);
  }

  .appBannerWrapper {
    padding: 0 var(--spacing-level-6);
  }

  .howToUse {
    margin: var(--spacing-level-6);
  }

  .ctaButton {
    margin: 0 var(--spacing-level-6);
  }

  .lockedBenefit .ctaButton {
    width: 100%;
    margin: var(--spacing-level-4) 0 0 0;
  }
}

.lockedBenefit {
  padding: var(--spacing-level-6) 0;
  height: calc(100vh - 252px);
  overflow-y: auto;
  scrollbar-width: none;

  @media (--under-m-breakpoint) {
    height: calc(100% - 246px);
  }

  .benefitDesc {
    padding: 0 var(--spacing-level-6);
  }

  .appBannerWrapper {
    padding: 0 var(--spacing-level-6);
  }
}

.lockedBenefit::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.fullScreenProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  height: 100vh;
}
