@import url("../../../../styles/_variables.css");

:root {
  --tag-height: 3.2rem;
  --tag-height-overflow-adjustment: calc(0px - ((var(--tag-height) - var(--body-line-height)) / 2));
  --tag-overspill-and-text-gap: 4px;
}

.item {
  position: relative;
}

.link {
  display: block;
  font-size: var(--body-size);
  letter-spacing: var(--body-spacing);
  line-height: var(--body-line-height);
  padding: var(--spacing-level-4) var(--spacing-level-5) var(--spacing-level-4) var(--nav-icon-space);
}

.link.hasTag {
  padding-right: 0;
}

.item:after {
  content: "";
  border-top: 1px solid var(--color-silver);
  position: absolute;
  top: 0;
  right: 0;
  left: var(--nav-icon-space);
}

.item.hideBorderTop:after {
  content: "";
  border-top: none;
}

.item:first-child:after {
  content: initial;
}

.active {
  background-color: var(--color-off-white);
  font-weight: bold;
  font-size: 1.4rem;
  position: relative;

  &:before {
    border-right: 4px solid var(--color-cerulean-blue);
    bottom: 0;
    content: "";
    left: -2px;
    position: absolute;
    top: 0;
  }
}

.iconWrapper {
  font-size: 0;
  left: 0;
  line-height: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: var(--nav-icon-space);
}

.iconInner {
  display: inline-block;
  position: relative;
}

.iconOffset {
  margin-bottom: 5px;
}

.badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.text,
.tagWrapper {
  display: table-cell;
  vertical-align: middle;
}

.tagWrapper {
  /* width: 0; - ensures table-cell layout stays as tight as possible to content */
  width: 0;
}

.tag {
  background: var(--color-black);
  color: var(--color-white);
  display: block;
  font-size: var(--small-size);
  font-weight: normal;
  line-height: var(--tag-height);
  margin: var(--tag-height-overflow-adjustment) var(--spacing-level-2) var(--tag-height-overflow-adjustment) 0;
  padding: 0 var(--spacing-level-4);
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

.text {
  border-right: var(--tag-overspill-and-text-gap) solid transparent;

  /* width: 100%; - ensures table-cell layout grows to fill the space, even if content is small enough not to need it */
  width: 100%;

  @media (--m-breakpoint) {
    border-right: 0;
  }
}

.navItem {
  border-bottom: 5px solid var(--asos-grey-20);
}

.shownOnlyOnLarge {
  display: none;

  @media (--m-breakpoint) {
    display: block;
  }
}

.loyaltyNavItemOptedOut {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.loyaltyNavItem {
  background: var(--color-loyalty-off-white) url("./img/grey-white-noise.svg") center center repeat-x;
  padding-left: var(--spacing-level-5);
  padding-top: var(--spacing-level-3);
  line-height: 1.85rem;
  
  &:hover {
    text-decoration: none;
  }
}

.active.loyaltyNavItem {
    &:before {
      border-right: 4px solid var(--color-violet);
    }
}
