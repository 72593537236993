@import url("../../../styles/_variables.css");

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.benefitTile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--spacing-level-3);
  min-height: 136px;
  text-align: left;
  background: var(--color-loyalty-off-white) url("../../../components/navigation/components/nav-item/img/mid-grey-white-noise.svg") center center repeat-x;
  background-size: 350px;
  animation: fadeIn 0.6s ease;

  .header {
    span {
      background-color: var(--color-full-black);
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;

      @media (--under-s-breakpoint) {
        margin-top: -1px;
      }
    }

    span:nth-of-type(2)      {
      display: inline-block;
      width: 1.3rem;
      height: 1.6rem;
      margin-top: 0;
      left: auto;
      top: auto;
    }

  }

  .test {
    width: 50%;
  }

  h2 {
    box-decoration-break: clone;
    line-height: 1.6rem;
    font-size: var(--h2-size);
    letter-spacing: -0.7px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 var(--spacing-level-1);
    display: inline;
    background-color: var(--color-full-black);
    color: var(--color-white);
  }

  a, button {
    -webkit-tap-highlight-color: transparent;
    font-size: var(--small-interactive-size);
    line-height: var(--small-interactive-line-height);
    letter-spacing: var(--small-interactive-spacing);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;

    div {
      width: 15rem;
    }
  }

  button {
    background: unset;
    border: unset;
    cursor: pointer;
    text-align: left;
    padding: 0;
  }

  .padlockIcon {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .infoIcon {
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (--under-s-breakpoint) {
    min-width: 100%;
  }
}

.tier2 {
  background: #9797ff url("../../../components/navigation/components/nav-item/img/mid-purple-white-noise.svg") center center repeat-x;
  background-size: 350px;
}

.tier3 {
  background: var(--asos-world-violet) url("../../../components/navigation/components/nav-item/img/purple-white-noise.svg") center center repeat-x;
  background-size: 350px;

  img {
    filter: invert(1);
  }
}

.unlocked {
  background-position: top;
  border-color: var(--color-silver);

  a:hover {
    text-decoration: none;
  }

  img {
    border: 1px solid var(--color-med-gray);
    border-radius: 6px;
  }

  h2 {
    background-color: var(--color-white);
    color: var(--color-full-black);
  }

  /* stylelint-disable no-descending-specificity */
  .header {
    span {
      background-color: var(--color-white);
    }
  }

  @media (--under-s-breakpoint) {
    background-position: 70% 0;
  }
}