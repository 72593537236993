@import url("../../../../styles/_variables.css");

.item {
  background: var(--color-white);
  display: block;
  font-size: var(--body-size);
  line-height: var(--body-line-height);
  padding: var(--spacing-level-4) var(--spacing-level-5) var(--spacing-level-4) var(--nav-icon-space);
  position: relative;
}

.item:after {
  border-top: 1px solid var(--color-silver);
  content: "";
  left: var(--nav-icon-space);
  position: absolute;
  right: 0;
  top: 0;
}

.item:first-child:after {
  content: initial;
}

.headerImage {
  animation: var(--full-page-loading-animation);
  background-color: var(--color-gallery-gray);
  padding-top: 30%;
}

.iconWrapper {
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: var(--nav-icon-space);
}

.loadingNavItemList {
  background-color: var(--color-white);
  margin-bottom: 5px;

  &:last-child {
    margin-bottom: 0;
  }
}

.hi {
  font-size: var(--body-size);
  margin: 0 auto;
  width: 80px;
}

.name {
  font-size: var(--h3-size);
  line-height: var(--h3-line-height);
  margin: 0 auto;
  min-height: var(--h3-line-height);
  padding-top: 6px;
  width: 120px;
}

.names {
  margin: auto;
  max-width: var(--text-max-width);
  padding: 18px var(--spacing-level-4) var(--spacing-level-4);
}

.profilePic {
  border-radius: 100%;
  border: 4px solid var(--color-white);
  display: inline-block;
  height: var(--nav-circle-size-mobile);
  line-height: var(--nav-circle-size-mobile);
  margin: auto;
  margin-top: calc((var(--nav-circle-size-mobile) / 2) * -1);
  width: var(--nav-circle-size-mobile);
}

.picAndName {
  background: var(--color-white);
  margin-top: calc(0px - var(--spacing-level-6));
  text-align: center;
}

.greeting {
  background-color: var(--color-white);
  margin-bottom: 5px;
}

@media (--xs-breakpoint) {
  .headerImage {
    padding-top: 0;
    height: var(--nav-header-image-xs);
  }
}

@media (--s-breakpoint) {
  .headerImage {
    height: var(--nav-header-image-s);
  }
}

@media (--m-breakpoint) {
  .profilePic {
    border: none;
    display: inline-block;
    height: var(--nav-circle-size-desktop);
    line-height: var(--nav-circle-size-desktop);
    margin: 0 0 0 -10px;
    min-width: var(--nav-circle-size-desktop);
    width: var(--nav-circle-size-desktop);
  }

  .headerImage {
    display: none;
  }

  .picAndName {
    margin-top: 0;
  }

  .names {
    display: inline-block;
    padding: 0 0 0 var(--spacing-level-3);
    vertical-align: middle;
    width: 60%;
    word-wrap: break-word;
  }

  .hi {
    width: 50%;
    margin: 0;
  }

  .name {
    width: 100%;
  }

  .greeting {
    padding: var(--spacing-level-7) var(--spacing-level-4) var(--spacing-level-7) 0;
    text-align: left;
  }
}

@media (--l-breakpoint) {
  .names {
    width: 70%;
  }

  .greeting {
    padding: var(--spacing-level-10) var(--spacing-level-4) var(--spacing-level-10) 0;
  }
}
