@import url("../../styles/_variables.css");

.blockDisplay {
  display: block;
  margin: auto;
}

.flexDisplay {
  display: flex;
  flex-direction: column;
}

.centerContent {
  align-items: center;
}

.verticalSpace {
  & > * {
    margin-top: var(--spacing-level-3);

    &:first-child {
      margin-top: 0;
    }
  }
}
