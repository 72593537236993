@import url("../../../../styles/_variables.css");

.openingTimes {
  border-collapse: unset;
  font-size: var(--h4-size);
  letter-spacing: var(--h4-spacing);
  line-height: var(--h4-line-height);
  padding-top: var(--spacing-level-2);
}

.day {
  padding-right: var(--spacing-level-6);
}
